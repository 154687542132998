import {
  faChevronDown,
  faCog,
  faEar,
  faHandSparkles,
  faHatWizard,
  faTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Editor from "@monaco-editor/react";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
// import { ResizableBox } from "react-resizable";
import ReactResizeDetector from "react-resize-detector";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-tabs/style/react-tabs.css";
import { AuthenticationStoreContext } from "../../../store/AuthenticationStore.js";
import { DHTML_StoreContext } from "../../../store/DHTML_Store.js";
import { EditorStoreContext } from "../../../store/EditorStore.js";
import { ProfileStoreContext } from "../../../store/ProfileStore.js";
import { FlowStoreContext } from "../../../store/FlowStore.js";
import { PublishMenuStoreContext } from "../../../store/Publish_Menu.js";
import { RemixableStoreContext } from "../../../store/RemixableStore";
import { WidgetStoreContext } from "../../../store/WidgetStore";
import { firebase } from "../../firebase/firebase.js";
import { useParams } from 'react-router-dom';
import { CommentsStoreContext } from "../../../store/CommentsStore.js";

import RemixWidgetModal from "../RemixWidgetModal.js";
import LoginM from "../LoginM.js";
import SigninM from "../SigninM.js";
import ReactModal from "react-modal";
// import { ResponsiveMonacoEditor } from "responsive-monaco-editor";
import { AuthContext } from "../AuthContext.js";
import { Config } from "../config/Config.js";
import { RoomContext } from "../RoomContext.js";
import UseLaterModal from "../UseLaterModal.js";
import FlowIframeRoom from "../FlowIframeRoom.js"

const CodeWizard = React.lazy(() => import("./codeWizard/codewizard.js"));
const CreateGuide = React.lazy(() => import("./CreateGuide.js"));
const Remix_Menu_Desktop = React.lazy(() => import("../Remix_Menu_Desktop.js"));
const PaymentModal = React.lazy(() => import("../PaymentModal.js"));
const ImproveFeedbackModal = React.lazy(() =>
  import("../ImproveFeedbackModal.js")
);
const AIRequestModal = React.lazy(() => import("../AIRequestModal"));
// const Flow = React.lazy(() => import("../Flow.js"));
const Post = React.lazy(() => import("./EditorModals/save-modal.js"));
const MoreModal = React.lazy(() => import("./EditorModals/more-modal.js"));
const SetAdvancedModal = React.lazy(() => import("./EditorModals/set-advanced-modal.js"));
const CommentsModal = React.lazy(() => import("../../Home/CommentsModal.js"))
const AICreateModal = React.lazy(() =>
  import("./EditorModals/ai-create-modal.js")
);

let loadHTML;
let loadCSS;
let loadJS;

const MobileTabMenu = React.lazy(() => import("../MobileTabMenu.js"));
const MobileTabPanel = React.lazy(() => import("../MobileTabPanel.js"));

const DetailMobileMenu = React.lazy(() => import("../DetailMobileMenu.js"));
let currentUserString = localStorage.getItem("flowroom-user");
let getAllSelectionPositioning;
let alreadyAddedCollaborators = [];
let textIndexes = [];
window.getAlready = false;
window.widgets = [];
window.textInstance = [];
window.imageIndexes = [];
let collaboratorsItem = [];
let resultCode;
let alreadyLoadedResultCode = false;
let alreadyLoadedAdvancedModal = false;
const ResizeHandle = () => (
  <div style={{ width: '20px', height: '20px', cursor: 'se-resize', background: 'url("/images/search-square-icon.png") center center no-repeat', backgroundSize: 'cover', border: '1px solid red' }}> </div>
);




const TestHandle = React.forwardRef((props, ref) => {
  const { handleAxis, ...restProps } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        position: "relative",
      }}
    >
      <div id="html-handle-box" style={{ position: "relative" }}>
        <div
          style={{ bottom: 12, right: "1px", overflow: "hidden" }}
          ref={ref}
          className={`react-resizable-handle  foo `}
          {...restProps}
        ></div>
      </div>
      <div id="css-handle-box" style={{ position: "relative" }}>
        <div
          style={{ bottom: 18, right: "8px" }}
          ref={ref}
          className={`react-resizable-handle  foo handle-${handleAxis}`}
          {...restProps}
        ></div>
      </div>
      <div id="js-handle-box" style={{ position: "relative" }}>
        <div
          style={{ bottom: 18, right: "8px" }}
          ref={ref}
          className={`react-resizable-handle  foo handle-${handleAxis}`}
          {...restProps}
        ></div>
      </div>
    </div>
  );
});

const MenuItem = ({ text, selected }) => {
  return (
    <div
      className={`menu-item ${selected ? "active" : ""}`}
      style={{ fontSize: 14, fontWeight: "bold" }}
    >
      {text}
    </div>
  );
};

export const Menu = (list, selected) =>
  list.map((el) => {
    const { name } = el;

    return <MenuItem text={name} key={name} selected={selected} />;
  });

String.prototype.replaceAt = function (input, search, replace, start, end) {
  return (
    input.slice(0, start) + input.slice(start, end).replace(search, replace) //+
    //input.slice(end)
  );
};

String.prototype.sreplace = function (start, length, word) {
  return this.replace(
    new RegExp("^(.{" + start + "}).{" + length + "}"),
    "$1" + word
  );
};

require("velocity-animate");
require("velocity-animate/velocity.ui");
var VelocityComponent = require("velocity-react/src/velocity-component");

let htmlEditor;
let cssEditor;
let jsEditor;
let gradeCodeHTMLEditor;
let gradeCodeCSSEditor;
let gradeCodeJSEditor;

let editorObject;
let timerRenderWhenReady;
let renderIframeHTML;
let htmlInit, cssInit, jsInit;
let typingTimer; //timer identifier
let isHTMLEditorReady = false;
let isCSSEditorReady = false;
let isJSEditorReady = false;
let rotateTriangles;
let reRenderOnce = 0;
let callNum = 0;
let scriptToAdd;
let isNormalize = false;
let nlpPrompt;
let nlpTemplate = {
  options: {
    engine: "davinci",
    maxTokens: 500,
    temperature: 0.5,
    topP: 1,
    presencePenalty: 0,
    frequencyPenalty: 0.5,
    bestOf: 1,
    n: 1,
    stream: false,
    stop: ["\n", "###"],
  },
  notPersonTemplate: ``,
  personSideTemplate: "",
};
let lastLineBeforeReturn = [];
let changed = false;
let t = 0;
let variables = [];
let fullVarList = [];
var two_line = /\n\n/g;
var one_line = /\n/g;
function linebreak(s) {
  return s.replace(two_line, "<p></p>").replace(one_line, "<br>");
}
var first_char = /\S/;
function capitalize(s) {
  return s.replace(first_char, function (m) {
    return m.toUpperCase();
  });
}
let optionsJump = [];

const GPTClient = (prompt) => {
  return fetch(
    `https://us-central1-flowroom-fd862.cloudfunctions.net/GPT3NLP`,
    {
      method: `POST`,
      body: JSON.stringify({ prompt: prompt }),
    },
    {
      mode: "cors",
    }
  );
};

let importArray = [];
let isBabel = false;
let widgetModifiedArr = [];
let count = 0;
let initialWidgetLoad = false;

let libraries = [];
let cssLibraries = [];
let cssLibrariesArr = [];
let librariesLoaded = false;
let langSelected = "";
let AIConfigArray = [];
function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const ResizableBox = ({ onResize, children, ...props }) => {
  const EditorStore = useContext(EditorStoreContext);

  const [size, setSize] = useState({ width: '100%', height: EditorStore.showAnimation ? 300 : 0 });
  const resizableRef = useRef(null);

  // useEffect(() => {
  //   // Guard clause to make sure the element is present
  //   const element = resizableRef.current;
  //   if (!element) return;

  //   // Setup ResizeObserver
  //   const observer = new ResizeObserver((entries) => {
  //     for (let entry of entries) {
  //       const { width, height } = entry.contentRect;
  //       // Assuming setSize is defined somewhere to update the local state
  //       setSize({ width, height }); 
  //       if (onResize) {
  //         onResize({ width, height });
  //       }
  //     }
  //   });

  //   // Start observing the element
  //   observer.observe(element);

  //   // Cleanup function
  //   return () => observer.disconnect();
  // }, [resizableRef, onResize]);

  return (
    <div
      ref={resizableRef}
      style={{
        width: size.width,
        height: size.height,
        resize: 'both',
        overflow: 'auto',
        ...props.style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

function TopTabsMobile({ id }) {
  // Use some local state to track which tab is selected.
  // Defaults to '' or something that matches your needs
  const [activeTab, setActiveTab] = useState('');

  const EditorStore = useContext(EditorStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const RemixableStore = useContext(RemixableStoreContext);
  const DHTMLStore = useContext(DHTML_StoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const [html, setHTML] = useState("");
  const [css, setCSS] = useState("");
  const [js, setJS] = useState("");
  const [codeBoxesHeight, setCodeBoxesHeight] = useState(220);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const [numberOfTimesGradeCall, setNumberOfTimesGradeCall] = useState(0);
  const [errorNumber, setErrorNumber] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorType, setErrorType] = useState("");
  const [updateConfirmation, setUpdateConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contentReady, setContentReady] = useState(false);
  const MAX_GRADE = numberOfTimesGradeCall;
  // libraries = PublishMenuStore.libraries;
  //localStorage.setItem("libraries", []);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const ProfileStore = useContext(ProfileStoreContext);

  const [isCurrentlyRemixing, setIsCurrentlyRemixing] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [editorsReady, setEditorsReady] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showWidget, setShowWidget] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3; // Set a maximum number of retries to avoid infinite loops
  // const [width, setWidth] = useState(window.innerWidth);
  const [boxSize, setBoxSize] = useState({ width: window.innerWidth, height: 300 });
  const editorParentRef = useRef(null); // Reference to the editor parent container
  const [maxWidth, setMaxWidth] = useState(0); // State to keep track of the maximum width
  const [width, setWidth] = useState('100%');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const parentRef = useRef();
const CommentsStore = useContext(CommentsStoreContext);
const hasSetShowWidget = useRef(false);
const [widgets, setWidgets] = useState({});
const [selected, setSelected] = useState(false);
const [showModal, setShowModal] = useState(false);
const [lastClickTime, setLastClickTime] = useState(false);
const [clickCount, setClickCount] = useState(false);
const [openModalRateLimit, setOpenModalRateLimit] = useState(false);
const [tryAgain, setTryAgain] = useState(false);
const [pending, setPending] = useState(false);
const [isBookmarked, setIsBookmarked] = useState(false);
const [showUseLater, setShowUseLater] = useState(false);
const [bookmarked, setBookmarked] = useState(false);
const [isCollaborator, setIsCollaborator] = useState(false);
  // existing code: currentUser, EditorStore, FlowStore, etc.

  useEffect(()=>{
  document.getElementsByClassName('top-tabs-mobile')[0].style.display = id === "new" ? "none":"block";

  },[])

  const [imageSrc, setImageSrc] = useState('../images/remix-icon-white.svg');
  const [imageSaveSrc, setImageSaveSrc] = useState('https://s3.us-west-2.amazonaws.com/test.flowroom.com/top-tab-save.png');
  const [imageCreateSrc, setImageCreateSrc] = useState('../images/create-with-ai-icon.svg');
  const [imageEditSrc, setImageEditSrc] = useState('https://s3.us-west-2.amazonaws.com/test.flowroom.com/top-tab-edit.png');
  const [imageBookmarkSrc, setImageBookmarkSrc] = useState('../bookmark-unselected-white.svg');

  const handleRemixMouseOver = () => {
    setImageSrc('../images/remix-icon-dark.svg');
  };

  const handleRemixMouseOut = () => {
    setImageSrc('../images/remix-icon-white.svg');
  };

  const handleSaveMouseOver = () => {
    setImageSaveSrc('../save_alt_black.svg');
  };

  const handleSaveMouseOut = () => {
    setImageSaveSrc('../save_alt.svg');
  };

  const handleEditWithAIMouseOver = () => {
    setImageEditSrc('../images/create-with-ai-icon-dark.svg');
  };

  const handleEditWithAIMouseOut = () => {
    setImageEditSrc('../images/create-with-ai-icon.svg');
  };

  const handleCreateWithAIMouseOver = () => {
    setImageCreateSrc('../images/create-with-ai-icon-dark.svg');
  };

  const handleCreateWithAIMouseOut = () => {
    setImageCreateSrc('../images/create-with-ai-icon.svg');
  };

  const handleBookmarkMouseOver = () => {
    setImageBookmarkSrc('../bookmark-unselected-black.svg');
  };

  const handleBookmarkMouseOut = () => {
    setImageBookmarkSrc('../bookmark-unselected-white.svg');
  };


  return (
    <div className="top-tabs-mobile">
      {/* ========== CREATE TAB ========== */}
      {/* {id === "new" && EditorStore.showSaveButton !== true ? (
        <div
          onClick={() => {
            // set "create" as active when clicked:
            setActiveTab('create');

            // existing logic:
            if (currentUser == null) {
              setShowAuthModal(true);
              return;
            }
            if (currentUser.displayName) {
              EditorStore.setShowCreateAI(true);
            } else {
              setShowAuthModal(true);
            }
          }}
          style={{
            ...{
              display: 'flex',
              color: 'white',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              padding: '4px 10px',
              justifyContent: 'space-around',
              fontFamily: 'quicksand',
              fontWeight: 'bold',
              fontSize: 12,
              textDecoration: 'none',
              border: '1px solid #1c1c1c !important',
              position: 'relative',
              zIndex: 1000,
            },
            // ******* THIS PART toggles green text + underline if activeTab === 'create' *******
            ...(activeTab === 'create'
              ? {
                  color: '#4FD975',
                  borderBottom: '2px solid #4FD975'
                }
              : {})
          }}
        >
          <img
            src={imageCreateSrc}
            style={{
              height: 20,
              width: 20,
              objectFit: 'contain',
              cursor: 'pointer'
            }}
          />
          <p
            style={{
              fontFamily: 'quicksand',
              fontSize: 12,
              marginBottom: 0,
              height: 17,
              marginLeft: 7,
              cursor: 'pointer'
            }}
          >
            Create
          </p>
        </div>
      ) : (
        ''
      )} */}

      {/* ========== EDIT TAB ========== */}
      {((currentUser !== null && currentUser.displayName !== null && EditorStore.showSaveButton === true)
        || FlowStore.isFlowOwner === true
        || isCollaborator === true
      ) ? (
        <div
          onClick={() => {
            setActiveTab('edit');
            // existing logic:
            if (currentUser == null) {
              setShowAuthModal(true);
              return;
            }
            if (currentUser.displayName) {
              EditorStore.setShowCreateAI(true);
            } else {
              setShowAuthModal(true);
            }
          }}
          style={{
            display: 'flex',
            color: 'white',
            alignItems: 'center',
            backgroundColor: '#1c1c1c',
            padding: '4px 10px',
            justifyContent: 'space-around',
            fontFamily: 'quicksand',
            fontWeight: 'bold',
            fontSize: 12,
            textDecoration: 'none',
            border: '1px solid #1c1c1c !important',
            position: 'relative',
            zIndex: 1000,
            // ******* if activeTab === 'edit', show green text & underline *******
            ...(activeTab === 'edit'
              ? {
                  color: '#4FD975',
                  borderBottom: '2px solid #4FD975'
                }
              : {})
          }}
        >
          <img
            src={imageEditSrc}
            style={{ height: 15, width: 20, objectFit: 'contain', cursor: 'pointer' }}
          />
          <p style={{ fontFamily: 'quicksand', fontSize: 12, marginBottom: 0, height: 17, marginLeft: 7, cursor: 'pointer' }}>
            Edit
          </p>
        </div>
      ) : (
        ''
      )}

      {/* ========== SAVE & POST TAB ========== */}
      {(FlowStore.isFlowOwner === true || id === 'new' || isCollaborator === true) && EditorStore.showSaveButton === true ? (
        <div
          onClick={() => {
            setActiveTab('save');
            // existing logic:
            if (currentUser == null) {
              setShowAuthModal(true);
              return;
            }
            if (currentUser.displayName) {
              EditorStore.setShowPostModal(true);
            } else {
              setShowAuthModal(true);
            }
          }}
          style={{
            display: 'flex',
            color: 'white',
            alignItems: 'center',
            backgroundColor: '#1c1c1c',
            padding: '4px 10px',
            justifyContent: 'space-around',
            fontFamily: 'quicksand',
            fontWeight: 'bold',
            fontSize: 12,
            textDecoration: 'none',
            border: '1px solid #1c1c1c !important',
            position: 'relative',
            zIndex: 1000,
            // ******* if activeTab === 'save', show green text & underline *******
            ...(activeTab === 'save'
              ? {
                  color: '#4FD975',
                  borderBottom: '2px solid #4FD975'
                }
              : {})
          }}
        >
          <img
            src={imageSaveSrc}
            style={{ height: 12, width: 12, objectFit: 'contain', cursor: 'pointer' }}
          />
          <p style={{ fontFamily: 'quicksand', fontSize: 12, marginBottom: 0, height: 17, marginLeft: 7, cursor: 'pointer', fontWeight:'lighter',color: '#FFFFFF' }}>
            Save &amp; Post
          </p>
        </div>
      ) : (
        ''
      )}

    </div>
  );
}



const RoomEditor = observer((props) => {
  const EditorStore = useContext(EditorStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const RemixableStore = useContext(RemixableStoreContext);
  const DHTMLStore = useContext(DHTML_StoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const [html, setHTML] = useState("");
  const [css, setCSS] = useState("");
  const [js, setJS] = useState("");
  const [codeBoxesHeight, setCodeBoxesHeight] = useState(220);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const [numberOfTimesGradeCall, setNumberOfTimesGradeCall] = useState(0);
  const [errorNumber, setErrorNumber] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorType, setErrorType] = useState("");
  const [updateConfirmation, setUpdateConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contentReady, setContentReady] = useState(false);
  const MAX_GRADE = numberOfTimesGradeCall;
  // libraries = PublishMenuStore.libraries;
  //localStorage.setItem("libraries", []);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const ProfileStore = useContext(ProfileStoreContext);

  const [isCurrentlyRemixing, setIsCurrentlyRemixing] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [editorsReady, setEditorsReady] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showWidget, setShowWidget] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3; // Set a maximum number of retries to avoid infinite loops
  // const [width, setWidth] = useState(window.innerWidth);
  const [boxSize, setBoxSize] = useState({ width: window.innerWidth, height: 300 });
  const editorParentRef = useRef(null); // Reference to the editor parent container
  const [maxWidth, setMaxWidth] = useState(0); // State to keep track of the maximum width
  const [width, setWidth] = useState('100%');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const parentRef = useRef();
const CommentsStore = useContext(CommentsStoreContext);
const hasSetShowWidget = useRef(false);
const [widgets, setWidgets] = useState({});
const [selected, setSelected] = useState(false);
const [showModal, setShowModal] = useState(false);
const [lastClickTime, setLastClickTime] = useState(false);
const [clickCount, setClickCount] = useState(false);
const [openModalRateLimit, setOpenModalRateLimit] = useState(false);
const [tryAgain, setTryAgain] = useState(false);
const [pending, setPending] = useState(false);
const [isBookmarked, setIsBookmarked] = useState(false);
const [showUseLater, setShowUseLater] = useState(false);
const [bookmarked, setBookmarked] = useState(false);
const [isCollaborator, setIsCollaborator] = useState(false);

useEffect(() => {
    const handleMessage = (event) => {
        console.log('Received message event:', event);
        console.log('Message data:', event.data);

        if (event.data.action === 'openWidgets') {
            console.log('Action to openWidgets received');
            setShowWidget(true);
            hasSetShowWidget.current = true; // Set the ref to true to avoid subsequent triggers
            setWidgets({widget: event.data.widget.widget, functionName:event.data.widget.functionName, widgetLabel:event.data.widget.widgetLabel, trainMode:event.data.widget.trainMode, startFunction:event.data.widget.startFunction, stopFunction: event.data.widget.stopFunction, items: event.data.widget.items, imageId: event.data.widget.imageId, imgSrc:event.data.widget.imgSrc });
            console.log('widgets_', widgets)
            try {
            document.getElementById(props.imageId).style.backgroundImage = `url(${widgets.imgSrc})`;
            } catch(e) {}

        }
    };

    window.addEventListener('message', handleMessage);

    // Clean up the event listener
    return () => {
        window.removeEventListener('message', handleMessage);
    };
}, [widgets]); // This effect should still depend on no external values to avoid re-subscription

// Optional: Reset mechanism when showWidget is set to false
useEffect(() => {
    const resetRefOnWidgetHide = () => {
        if (!showWidget) {
            hasSetShowWidget.current = false; // Reset ref when showWidget is false
        }
    };

    resetRefOnWidgetHide();
}, [showWidget]); // Depend on showWidget to reset the ref




//   useEffect(() => {
 
//     // Assuming editorParentRef is a ref to the parent container you're observing
//     if (editorParentRef.current) {
//         const resizeObserver = new ResizeObserver((entries) => {
//             // Temporarily disconnect to avoid feedback loop
//             resizeObserver.disconnect();

//             // Perform size adjustments here
//             // This might be setting state that triggers re-render or direct DOM manipulation, which should be minimal
//             // For example, adjusting related elements' sizes but not the ResizableBox itself to avoid conflicts
//             // ...

//             // Reconnect after adjustments
//             // It might be necessary to wait for updates to complete if they're asynchronous (e.g., setState callbacks)
//             setTimeout(() => {
//                 resizeObserver.observe(editorParentRef.current);
//             }, 0); // Using setTimeout to defer re-observation to the next event loop cycle can help avoid immediate feedback
//         });

//         resizeObserver.observe(editorParentRef.current);

//         // Cleanup
//         return () => resizeObserver.disconnect();
//     }
// }, [editorParentRef]); // Re-run if the ref changes







  const containerRef = useRef(null);



  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setBoxSize((prevSize) => ({
          ...prevSize,
          width: containerRef.current.offsetWidth,
        }));
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize(); // Initial size update

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const room = useContext(RoomContext);
console.log('room', room)
 
  let { id } = useParams();
  const flowId = id;
  const fetchWithRetry = async (url, retries = 3, backoff = 300) => {
    try {
      const response = await fetch(url, { mode: "cors" });
      if (!response.ok)
        throw new Error(`Fetch failed with status ${response.status}`);
      return await response.text();
    } catch (error) {
      if (retries > 0) {
        setTimeout(() => {
          return fetchWithRetry(url, retries - 1, backoff * 2);
        }, backoff);
      } else {
        throw error;
      }
    }
  };

  
  useEffect(() => {
    if(alreadyLoadedAdvancedModal == false) {

    if (id === "new" && EditorStore.alreadySetAdvancedMode === false && alreadyLoadedAdvancedModal === false) {
      EditorStore.setShowAdvancedModal(true);
      // Set alreadySetAdvancedMode to true to prevent modal from opening again
      alreadyLoadedAdvancedModal = true;

    }

  }
  }, [alreadyLoadedAdvancedModal]); // Also, add 'id' as a dependency if it's a prop or state
  



  useEffect(() => {
    // Disable scrolling on the body
    document.body.style.overflow = "hidden";

    // Cleanup function to re-enable scrolling
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  // useEffect(() => {
  //   if(flowId !== undefined && flowId !== null && flowId !== "") {
  //   const likesCollectionRef = firebase.firestore().collection('likes');

  //   // Query to filter likes by 'flowId' matching 'room.flowId'
  //   const query = likesCollectionRef.where("flowId", "==", flowId);

  //   const unsubscribe = query.onSnapshot(querySnapshot => {
  //     // Update state with the number of documents (likes)
  //     EditorStore.setLikes(querySnapshot.size);
  //   }, error => {
  //     console.error("Error fetching likes: ", error);
  //   });

  //   // Cleanup on unmount
  //   return () => unsubscribe();
  // }
  // }, [flowId]); // Dependency array to re-run effect if this value changes


  useEffect(() => {
    if(flowId !== undefined && flowId !== null && flowId !== "") {
    const commentsCollectionRef = firebase.firestore().collection('comments');

    // Adjust the query to filter by 'flowId' matching 'room.flowId' only
    const query = commentsCollectionRef.where("flowId", "==", flowId);

    const unsubscribe = query.onSnapshot(querySnapshot => {
      // Update state with the number of documents
      EditorStore.setComments(querySnapshot.size); /* EditorStore.setComments needs renaming is not actual comments but the comment number */
    }, error => {
      console.error("Error fetching comments: ", error);
    });

    // Cleanup on unmount
    return () => unsubscribe();
  }
  }, [flowId]); // Dependency array to re-run effect if this value changes


  useEffect(()=>{
    setIsCurrentlyRemixing(false);
  },[EditorStore.showRemixWithWidgetButton]);

    // Fetch user photo based on the username prop when the component mounts or username changes
    useEffect(() => {
      const fetchUserPhoto = async () => {
        if (!EditorStore.username) return;
        try {
          const userSnapshot = await  firebase
          .firestore().collection("users").where("username", "==", EditorStore.username).limit(1).get();
          if (!userSnapshot.empty) {
            const userData = userSnapshot.docs[0].data();
            EditorStore.setUrlPhoto(userData.urlPhoto || "/images/Flowroom_Default_Avatar.png");
          } else {
            EditorStore.setUrlPhoto("/images/Flowroom_Default_Avatar.png");
          }
        } catch (error) {
          console.error("Error fetching user photo:", error);
          EditorStore.setUrlPhoto("/images/Flowroom_Default_Avatar.png");

        }
      };
      fetchUserPhoto();
    }, [EditorStore.username]);
  

  
  if (librariesLoaded === false) {
    firebase
      .firestore()
      .collection("flows")
      .doc(flowId)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        let data = doc.data();
        console.log("[Editor] data:", data);
        if (
          data !== undefined &&
          (data !== null && data.libraries) !== undefined &&
          data.libraries !== null
        ) {
          localStorage.setItem("libraries", JSON.stringify(data.libraries));
        }
        if (
          data !== undefined &&
          (data !== null && data.cssLibraries) !== undefined &&
          data.cssLibraries !== null
        ) {
          localStorage.setItem("cssLibraries", JSON.stringify(cssLibrariesArr));
        }

        libraries = JSON.parse(localStorage.getItem("libraries"));
        cssLibraries = JSON.parse(localStorage.getItem("cssLibraries"));
        librariesLoaded = true;
      });
  }

  useEffect(() => {
    const fetchData = async () => {

    firebase
      .firestore()
      .collection("flows")
      .doc(flowId)
      .get()
      .then(async (doc) => {
        if (!doc.exists) {
          const parts = window.location.pathname.split("/");
          const id = parts.pop() || parts.pop();

          if (
            currentUser?.isLoggedIn &&
            id === "new" &&
            !alreadyAddedCollaborators.includes(currentUser.displayName)
          ) {
            const collaborator = {
              user: currentUser.displayName,
              skill: "creator",
              label: "You",
              profilePic: currentUser.photoURL,
            };
            collaboratorsItem.push(collaborator);
            alreadyAddedCollaborators.push(currentUser.displayName);
            PublishMenuStore.setCollaborators(collaboratorsItem);
          }
          return;
        }
        const {
          libraries,
          widgets,
          imageIndexes,
          textInputIndexes,
          colorSelectIndexes,
          editorLockedSelected,
          disableComments,
          isAR,
          isVR,
          isLive,
          isChrome,
          isSafari,
          isFirefox,
          isOpera,
          isVivaldi,
          isBrave,
          comments,
          likes,
          description,
          details,
          username,
          urlPhoto,
          date,
          isAReShare,
          flowId,
          AITrainerModel,
          remixes,
          collaborators,
          isPinned,
          visibility,
          isRemixableByDevOnly,
          title,
          flowIdOP,
          remixedBy,
          isRemixable,
          userId,
          deductCreditFrom,
          isAResult,
          hasAudio,
          resultCode,
          flowData,
          flowPrice,
          flowIsMonetized,
          flowIsApproved,
          flowMonthly,
          flowFreeTrial,
          flowAnnually,
          paidRemix,
          flowPerMonth,
          flowPerYear,
          flowPreview,
          flowThumbnail,
          flowDays,
          flowNumberOfUses,
          flowPreviewId,
          flowIsMonetizedInitial,
          flowPriceInitial,
          flowIsApprovedInitial,
          flowFreeTrialInitial,
          paidRemixInitial,
          flowThumbnailInitial,
          flowPerMonthInitial,
          flowPerYearInitial,
          flowDaysInitial,
          flowNumberOfUsesInitial,
          flowPreviewInitial,
          loadThumbnail,
          loadThumbnailInitial,
          isRemixableInitial,
          loadThumbnailPaid,
          loadThumbnailPaidInitial,
          isRemixableByEveryone,
          isRemixableByEveryoneInitial,
          isRemixableByDevOnlyInitial,
          isRemixableByMeOnly,
          isRemixableByMeOnlyInitial,
          aiModifyRemix,
          aiModifyRemixInitial,
          editorRemix,
          editorRemixInitial,
          specificRemixing,
          specificRemixingInitial,
          remixPrice,
          remixPriceInitial,
          flowMonthlyInitial,
          flowPreviewIdInitial,
          flowAnnuallyInitial,
          yourRole,
          urlHTML,
          urlCSS,
          urlJS,
          shareCount,
          isBlocked,
          remixedCount,
          isRemixed
        } = doc.data();
        PublishMenuStore.setLibraries(libraries);
        if (widgets) {
          WidgetStore.setWidgets(widgets);
          WidgetStore.setImageIndexes(imageIndexes ?? []);
          WidgetStore.setTextInputIndexes(textInputIndexes ?? []);
          WidgetStore.setColorSelectIndexes(colorSelectIndexes ?? []);
        }
        EditorStore.setSaveFlowDataRemixFlowId("");
        EditorStore.setEditorLockedSelected(editorLockedSelected);
        EditorStore.setDisableComments(disableComments);
        EditorStore.setIsAR(isAR);
        EditorStore.setIsVR(isVR);
        EditorStore.setIsLive(isLive);
        EditorStore.setIsChrome(isChrome);
        EditorStore.setIsSafari(isSafari);
        EditorStore.setIsFirefox(isFirefox);
        EditorStore.setIsOpera(isOpera);
        EditorStore.setIsVivaldi(isVivaldi);
        EditorStore.setIsBrave(isBrave);
        EditorStore.setCurrentFlowCommentsNumber(comments);
        EditorStore.setCurrentFlowLikesNumber(likes);
        EditorStore.setCurrentFlowDescription(description);
        EditorStore.setCurrentFlowDetails(details);
        EditorStore.setUserName(username);
        // EditorStore.setUrlPhoto(urlPhoto);
        EditorStore.setDate(date);
        EditorStore.setIsAReShare(isAReShare);
        EditorStore.setCurrentFlowId(flowId);
        EditorStore.setAITrainerModel(AITrainerModel);
        EditorStore.setRemixes(remixes);
        EditorStore.setCollaborators(collaborators);
        EditorStore.setIsPinned(isPinned);
        PublishMenuStore.setVisibility(visibility);
        EditorStore.setIsRemixableByDevOnly(isRemixableByDevOnly);
        EditorStore.setTitle(title);
        EditorStore.setFlowId(flowId);
        PublishMenuStore.setRemixedBy(remixedBy);
        PublishMenuStore.setCollaborators(collaborators);
        PublishMenuStore.setShowShare(true);
        PublishMenuStore.setFlowIdOP(flowIdOP ?? "none");
        EditorStore.setRemixedLocked(isRemixable);
        PublishMenuStore.setIsRemixable(isRemixable);
        EditorStore.setDescription(description);
        if (currentUser) {
          FlowStore.setIsFlowOwner(userId === currentUser.uid);
        }
        EditorStore.setDeductCreditFrom(deductCreditFrom);
        EditorStore.setUID(userId);
        EditorStore.setIsAResult(isAResult);
        EditorStore.setHasAudio(hasAudio);
        EditorStore.setFlowIsMonetized(flowIsMonetized);
        EditorStore.setFlowIsMonetizedInitial(flowIsMonetized);
        EditorStore.setFlowPrice(flowPrice);
        EditorStore.setFlowPriceInitial(flowPrice);
        EditorStore.setFlowIsApproved(flowIsApproved);
        EditorStore.setFlowIsApprovedInitial(flowIsApproved);
        EditorStore.setFlowFreeTrial(flowFreeTrial);
        EditorStore.setFlowFreeTrialInitial(flowFreeTrial);
        EditorStore.setPaidRemix(paidRemix);
        EditorStore.setPaidRemixInitial(paidRemix);
        EditorStore.setFlowPreview(flowPreview);
        EditorStore.setFlowPreviewInitial(flowPreview);
        EditorStore.setFlowThumbnail(flowThumbnail);
        EditorStore.setFlowThumbnailInitial(flowThumbnail);
        EditorStore.setFlowPerMonth(flowPerMonth);
        EditorStore.setFlowPerMonthInitial(flowPerMonth);
        EditorStore.setFlowPerYear(flowPerYear);
        EditorStore.setFlowPerYearInitial(flowPerYear);
        EditorStore.setMonetized(flowIsMonetized);
        EditorStore.setMonetizedInitial(flowIsMonetized);
        EditorStore.setFlowDays(flowDays);
        EditorStore.setFlowDaysInitial(flowDays);
        EditorStore.setFlowNumberOfUses(flowNumberOfUses);
        EditorStore.setFlowNumberOfUsesInitial(flowNumberOfUses);
        EditorStore.setFlowPreviewId(flowPreviewId);
        EditorStore.setFlowPreviewIdInitial(flowPreviewId);
        EditorStore.setLoadThumbnail(loadThumbnail);
        EditorStore.setLoadThumbnailInitial(loadThumbnail);
        EditorStore.setIsRemixable(isRemixable);
        EditorStore.setIsRemixableInitial(isRemixable);
        EditorStore.setLoadThumbnailPaid(loadThumbnailPaid);
        EditorStore.setLoadThumbnailPaidInitial(loadThumbnailPaid);
        EditorStore.setIsRemixableByEveryone(isRemixableByEveryone);
        EditorStore.setIsRemixableByEveryoneInitial(isRemixableByEveryone);
        EditorStore.setIsRemixableByDevOnlyInitial(isRemixableByDevOnly);
        EditorStore.setIsRemixableByMeOnly(isRemixableByMeOnly);
        EditorStore.setIsRemixableByMeOnlyInitial(isRemixableByMeOnly);

        EditorStore.setAIModifyRemix(aiModifyRemix);
        EditorStore.setAIModifyRemixInitial(aiModifyRemix);
        EditorStore.setEditorRemix(editorRemix);
        EditorStore.setEditorRemixInitial(editorRemix);
        EditorStore.setSpecificRemixing(specificRemixing);
        EditorStore.setSpecificRemixingInitial(specificRemixing);
        EditorStore.setRemixPrice(remixPrice);
        EditorStore.setRemixPriceInitial(remixPrice);
        EditorStore.setFlowData(flowData);
        EditorStore.setFlowAnnually(flowAnnually);
        EditorStore.setFlowAnnuallyInitial(flowAnnually);

        EditorStore.setIsProAccount(true);
        EditorStore.setYourRole(yourRole);
        EditorStore.setURLHTML(urlHTML);
        EditorStore.setURLCSS(urlCSS);
        EditorStore.setURLJS(urlJS);
        EditorStore.setUserId(userId);
        EditorStore.setShareCount(shareCount);
        EditorStore.setIsBlocked(isBlocked);
        EditorStore.setLikes(likes);
        EditorStore.setRemixedCount(remixedCount);
        EditorStore.setIsRemixed(isRemixed);
        EditorStore.setFlowIdOP(flowIdOP);

        console.log("flowIsMonetized", flowIsMonetized);
        console.log("flowIsMonetizedInitial", flowIsMonetizedInitial);
        console.log("flowPrice", flowPrice);
        console.log("flowPriceInitial", flowPriceInitial);
        console.log("flowIsApproved", flowIsApproved);
        console.log("flowIsApprovedInitial", flowIsApprovedInitial);
        console.log("flowMonthly", flowMonthly);
        console.log("flowMonthlyInitial", flowMonthlyInitial);
        console.log("flowAnnually", flowAnnually);
        console.log("flowAnnuallyInitial", flowAnnuallyInitial);
        console.log("paidRemix", paidRemix);
        console.log("paidRemixInitial", paidRemixInitial);
        console.log("flowPerMonth", flowPerMonth);
        console.log("flowPerMonthInitial", flowPerMonthInitial);
        console.log("flowIsMonetized", flowIsMonetized);
        console.log("flowIsMonetizedInitial", flowIsMonetizedInitial);
        console.log("flowPerYear", flowPerYear);
        console.log("flowPerYearInitial", flowPerYearInitial);
        console.log("flowPreview", flowPreview);
        console.log("flowPreviewInitial", flowPreviewInitial);
        console.log("flowThumbnail", flowThumbnail);
        console.log("flowThumbnailInitial", flowThumbnailInitial);
        console.log("flowDays", flowDays);
        console.log("flowDaysInitial", flowDaysInitial);
        console.log("flowNumberOfUses", flowNumberOfUses);
        console.log("flowNumberOfUsesInitial", flowNumberOfUsesInitial);
        console.log("flowPreviewId", flowPreviewId);
        console.log("flowPreviewIdInitial", flowPreviewIdInitial);
        console.log("flowFreeTrial", flowFreeTrial);
        console.log("flowFreeTrialInitial", flowFreeTrialInitial);
        console.log("loadThumbnail", loadThumbnail);
        console.log("loadThumbnailInitial", loadThumbnailInitial);
        console.log("isRemixable", isRemixable);
        console.log("isRemixableInitial", isRemixableInitial);
        console.log("loadThumbnailPaid", loadThumbnailPaid);
        console.log("loadThumbnailPaidInitial", loadThumbnailPaidInitial);
        console.log("isRemixableByEveryone", isRemixableByEveryone);
        console.log(
          "isRemixableByEveryoneInitial",
          isRemixableByEveryoneInitial
        );
        console.log("isRemixableByDevOnly", isRemixableByDevOnly);
        console.log("isRemixableByDevOnlyInitial", isRemixableByDevOnlyInitial);
        console.log("isRemixableByMeOnly", isRemixableByMeOnly);
        console.log("isRemixableByMeOnlyInitial", isRemixableByMeOnlyInitial);
        console.log("aiModifyRemix", aiModifyRemix);
        console.log("aiModifyRemixInitial", aiModifyRemixInitial);
        console.log("editorRemix", editorRemix);
        console.log("editorRemixInitial", editorRemixInitial);
        console.log("specificRemixing", specificRemixing);
        console.log("specificRemixingInitial", specificRemixingInitial);
        console.log("remixPrice", remixPrice);
        console.log("remixPriceInitial", remixPriceInitial);

       console.log('urlHTML', urlHTML)

        if (isAResult) {
          resultCode = resultCode;
        }

        // localStorage.setItem("widgetHTML", null);
        // localStorage.setItem("widgetCSS", null);
        // localStorage.setItem("widgetJS", null);
        // const iframe = document.getElementById("undefined_output_frame");

       // 1) Fetch with retry:
const fetchWithRetry = async (url, retries = 7, backoff = 300) => {
  try {
    console.log(`Attempting to fetch: ${url}`);
    const response = await fetch(url, { mode: "cors" });
    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}`);
    }
    console.log(`Success fetching: ${url}`);
    return await response.text();
  } catch (error) {
    console.log(`Error fetching ${url}: ${error.message}, retries left: ${retries}`);
    if (retries > 0) {
      await new Promise(resolve => setTimeout(resolve, backoff));
      return fetchWithRetry(url, retries - 1, backoff * 2);
    } else {
      console.log(`No more retries left for ${url}`);
      throw error;
    }
  }
};

// 2) Helper function that fetches all resources (HTML, CSS, JS), sets them into state, 
//    and then calls the rendering function.
async function loadResourcesAndRenderIframe(urlHTML, urlCSS, urlJS) {
  // Prepare fetch promises for URLs that are not undefined/empty
  const fetchPromises = [urlHTML, urlCSS, urlJS].map(url =>
    url ? fetchWithRetry(url) : Promise.resolve(undefined)
  );

  // Execute all fetch promises
  const [htmlContent, cssContent, jsContent] = await Promise.all(fetchPromises);

  // Set content only for fetched resources
  if (htmlContent !== undefined) setHTML(htmlContent);
  if (cssContent !== undefined) setCSS(cssContent);
  if (jsContent !== undefined) setJS(jsContent);

  // Render the iframe (delay to ensure state is updated before rendering)
  setTimeout(() => {
    renderIframeHTML();
  }, 500);
}

// 3) Main logic that first attempts to load resources and then checks if the iframe is empty.
//    If it's empty, it tries again, up to 30 seconds.
  // Keep track of when we started checking
  const startTime = Date.now();
  const maxDurationMs = 30000; // 30s. Adjust as desired.

  // We attempt an initial load:
  try {
    await loadResourcesAndRenderIframe(urlHTML, urlCSS, urlJS);
  } catch (error) {
    console.error("Failed initial load:", error);
    setErrorMessage("Failed to load resources. Please try again later.");
    setLoading(false);
    return; // stop if initial load absolutely fails
  }

  // Optionally handle the case where all three URLs are blank or undefined
  // so we can attempt again in the effect, but you could adjust as needed:
  if (
    ((!urlHTML || urlHTML === "") &&
      (!urlCSS || urlCSS === "") &&
      (!urlJS || urlJS === "")) &&
    retryCount < maxRetries
  ) {
    setRetryCount(prevCount => prevCount + 1); // Increment retry count
    setIsDataFetched(fetched => !fetched); // Toggle to re-trigger useEffect
  }

  // This interval will check the iframe content repeatedly until 
  // it either finds something in the <body> or times out.
  const intervalId = setInterval(async () => {
    const now = Date.now();
    // If we've been checking too long, stop
    if (now - startTime > maxDurationMs) {
      console.warn("Iframe content still empty after 30 seconds. Stopping checks.");
      clearInterval(intervalId);
      return;
    }

    const iframe = document.getElementById("undefined-output-frame");
    if (!iframe) {
      // If the iframe doesn't exist in DOM, no reason to keep checking
      console.warn("No iframe found with id 'undefined-output-frame'. Stopping checks.");
      clearInterval(intervalId);
      return;
    }

    // Safely grab the iframe’s document
    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDoc) return; // might not be ready yet

    // Check if body is empty
    const bodyContent = iframeDoc.body?.innerHTML?.trim() || "";
    if (bodyContent.length > 0) {
      // The iframe has content now; set up postMessage as needed
      console.log("Iframe content loaded successfully.");

      // Optionally define onload logic
      iframe.onload = () => {
        iframe.contentWindow.postMessage({ type: "initialData", data: flowData }, "*");
      };

      // Stop our interval checks
      clearInterval(intervalId);
    } else {
      // Body is still empty; try re-fetching resources & re‐rendering
      console.log("Iframe body is empty; attempting re‐load of resources...");

      try {
        await loadResourcesAndRenderIframe(urlHTML, urlCSS, urlJS);
      } catch (error) {
        console.error("Re‐load attempt failed:", error);
        // Optionally handle the failure here
      }
    }
  }, 1000); // check once every second

  // Cleanup interval if the component unmounts (assuming React environment)
  return () => clearInterval(intervalId);



  
      });

      

   
    }




    fetchData();


  }, [isDataFetched, retryCount]);

  useEffect(() => {
    if (document.getElementById("mobile-info") !== null) {
      if (document.getElementById("vertical-tab-details-tab") !== null) {
        if (WidgetStore.showRemixOrDescription === "description") {
          document.getElementById("mobile-info").click();
          // document.getElementById("vertical-tab-details-tab").click();
        }
        if (WidgetStore.showRemixOrDescription === "remix") {
        }
      }
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [WidgetStore.showRemixOrDescription]);

  let widgetCopy = WidgetStore.currentWidgetCopy;

  function doneTyping() {
    EditorStore.setCodeModification(true);
    EditorStore.setTurnOffRemix(
      true
    ); 
    if(EditorStore.showRemixWithWidgetButton == true) {
      EditorStore.setShowRemixWithWidgetButton(false);
    }
    /*this just turns the remix switch to off, not actual remixing*/
    // EditorStore.setFlowIsApproved(false);
    renderIframeHTML();
  
  }

  function doneTypingNLP() {
    renderIframeHTML();
  }

  htmlEditor = useRef(null);
  cssEditor = useRef();
  jsEditor = useRef();
  editorObject = useRef();
  gradeCodeHTMLEditor = useRef();
  gradeCodeCSSEditor = useRef();
  gradeCodeJSEditor = useRef();

  function handleHTMLEditorDidMount(editor, monaco) {
    setTimeout(() => {
      htmlEditor.current = editor;
    });
    editorObject = editor;
  }

  useEffect(() => {
    if (WidgetStore.deleteWidgetItem === true) {
      WidgetStore.setDeleteWidgetItem(false);
      widgets.splice(WidgetStore.widgetDeleteInstance, 1);
      if (textIndexes !== undefined) {
        textIndexes.splice(WidgetStore.widgetDeleteInstance, 1);
      }
      WidgetStore.setWidgets(widgets);
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [WidgetStore.deleteWidgetItem]);



  function handleCSSEditorDidMount(editor) {
    //setTimeout(() => {
      cssEditor.current = editor;
    //}, 500);
  }

  function handleJSEditorDidMount(editor, monaco) {
    //setTimeout(() => {
      jsEditor.current = editor;
      let range = new monaco.Range(3, 1, 5, 1);
      console.log("range", range);
   // }, 500);
  }

  function clearSelection() {
    var sel;
    if ((sel = document.selection) && sel.empty) {
      sel.empty();
    } else {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
      var activeEl = document.activeElement;
      if (activeEl) {
        var tagName = activeEl.nodeName.toLowerCase();
        if (
          tagName == "textarea" ||
          (tagName == "input" && activeEl.type == "text")
        ) {
          // Collapse the selection to the end
          activeEl.selectionStart = activeEl.selectionEnd;
        }
      }
    }
  }

  useEffect(() => {
    if (document.getElementById("html-options-icons") !== null) {
      let resizeWidth =
        document.getElementById("html-options-icons").clientWidth;
      if (resizeWidth < 224) {
        if (
          document.getElementsByClassName("html-options-icons")[0] !== undefined
        ) {
          // document.getElementsByClassName(
          //   "html-options-icons"
          // )[0].style.display = "none";
        }
      }
    }
    renderContent();
    setTimeout(() => {
      clearSelection();
    }, 100);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);
  useEffect(() => {
    if (EditorStore.showAnimation === true) {
      if (codeBoxesHeight < 220) {
        setCodeBoxesHeight(220);
      }
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  useEffect(() => {
    // if (FlowStore.isLoaded) {
    // loadFlow();
    PublishMenuStore.setPostErrorMessage("");
    // }
    FlowStore.setIsLoaded(false);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  useEffect(() => {
    if (EditorStore.dhtmlEditor == true) {
      setHTML(EditorStore.dhtml_HTML);
      setCSS(EditorStore.dhtml_CSS);
      setJS(EditorStore.dhtml_JS);

      setTimeout(() => {
        renderIframeHTML();
      }, 500);

      EditorStore.setDHTMLEditor(false);
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [EditorStore.dhtmlEditor]);


  const editorDragStart = () => {
    document.getElementById("out-cover").style.display = "block";
  };

  const editorDragStop = () => {
    document.getElementById("out-cover").style.display = "none";
  };



  async function checkIfFlowIsBookmarked() {
    try {
      if (!EditorStore || !EditorStore.flowId) {
        console.error("Invalid room data provided.");
        return;
      }
  
      const bookmarksCollection = firebase.firestore().collection("bookmarks");
  
      // Check the 'bookmarks' collection for an entry matching 'room.flowId'
      let querySnapshot = await bookmarksCollection
        .where("flowId", "==", EditorStore.flowId)
        .where("username", "==", currentUser !== null && currentUser.displayName) // Ensure currentUser is defined
        .limit(1)
        .get();
  
  
      // Set selected based on whether a matching bookmark was found
      EditorStore.setSelectedBookmark(!querySnapshot.empty);
  
    } catch (error) {
      console.error("Error checking if flow is bookmarked:", error);
    }
  }
  
  
  
  useEffect(() => {
    checkIfFlowIsBookmarked();
    // Since this useEffect does not depend on variables that change, there's no need to return a cleanup function
  }, [EditorStore.flowId]); // Assuming room does not change, otherwise add room as a dependency
  
    const bookmarkFlow = async () => {
      if (!AuthenticationStore.userId) {
        console.log("User not authenticated.");
          setShowModal(true);
           return;
         
        return;
      }
      const currentTime = Date.now();
      if (currentTime - lastClickTime > 3000) {
        // Reset the counter if more than 3 seconds have passed since the last recorded click
        setClickCount(0);
      }
    
      // Increment click count and update last click time
      setClickCount(prevCount => prevCount + 1);
      setLastClickTime(currentTime);
    
      // Allow up to 3 rapid clicks before enforcing a cooldown
      if (clickCount >= 20) {
        WidgetStore.setErrorModalMessage("You clicked too many times. Please wait a few seconds and try again.")
        setOpenModalRateLimit(true);
        setTryAgain(true);  // Make sure setTryAgain is implemented to handle retry logic
        return;
      }
    
      setPending(true);
      const docPin = await firebase
        .firestore()
        .collection("bookmarks")
        .doc(AuthenticationStore.userId + ":" + EditorStore.flowId)
        .get();
    
      if (docPin.exists) {
        setIsBookmarked(false);
        setBookmarked(bookmarked - 1);
        await firebase
          .firestore()
          .collection("bookmarks")
          .doc(AuthenticationStore.userId + ":" + EditorStore.flowId)
          .delete();
          EditorStore.setSelectedBookmark(false);
        /* do this conditional because bookmark works in feed too but in feed we dont want to change the ProfileStore */
        if(ProfileStore.profileItemSelected === "useLater" && currentUser.displayName === id ) {
        ProfileStore.setProfileItemSelected("");
        setTimeout(()=> {
          ProfileStore.setProfileItemSelected("useLater")
        },100)
       }
        setPending(false);
      } else {
        setIsBookmarked(true);
        setBookmarked(bookmarked + 1);
    
        await firebase
          .firestore()
          .collection("bookmarks")
          .doc(AuthenticationStore.userId + ":" + EditorStore.flowId)
          .set({
            flowId: EditorStore.flowId,  // Verify 'id' usage, replace with 'flowId' if necessary
            likeId: AuthenticationStore.userId + ":" + EditorStore.flowId,
            userId: AuthenticationStore.userId,
            timestamp: new Date().toISOString(),
            username:currentUser.displayName
          }, { merge: true });
        setPending(false);
        EditorStore.setSelectedBookmark(true);
        const showModal = localStorage.getItem('flow_room_showModal');
        if(showModal !== 'false') {
        setShowUseLater(true);
        }
  
        // addPinnedNotification();
      }
    };
  
  



  // TODO: replace production http with https
  let replaceInsecureUrls = (string) => {
    return ("" + string).replace(
      /http:\/\/test.flowroom.com/g,
      "https://demo.flowroom.com"
    );
  };

  let renderContent = (
    before = null,
    htmlUpdate = null,
    cssUpdate = null,
    jsUpdate = null
  ) => {
    let base_tpl =
      "<!doctype html>\n" +
      "<html >\n\t" +
      "<head>\n\t\t" +
      '<meta charset="utf-8">\n\t\t' +
      "<title>Flowroom</title>\n\n\t\t\n\t" +
      "</head>\n\t" +
      "<body>\n\t\n\t" +
      "</body>\n" +
      "</html>";

    let fontawesome =
      '<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous"></link> ';

    let prepareSource = () => {
      //console.log("[Editor] prepareSource");
      try {
        let html = htmlEditor ? htmlEditor.current.getValue() : "";
        let css = cssEditor ? cssEditor.current.getValue() : "";
        let js = jsEditor ? jsEditor.current.getValue() : "";

        DHTMLStore.setDHTML(html, css, js);
        if(html.trim() !== "" || css.trim() !== "" || js.trim() !== "" ) {
          EditorStore.setShowSaveButton(true);
        } else {
          EditorStore.setShowSaveButton(false);
        }
        //console.log("[Editor] prepareSource html:", html, "css:", css, "js:", js);

        let dhtmlObj = { html: html, css: css, js: js };
        localStorage.setItem("dhtml", JSON.stringify(dhtmlObj));

        let firebaseapp =
          '<script src="https://www.gstatic.com/firebasejs/8.10.0/firebase-app.js" crossorigin></script>';
        let firebase =
          '<script src="https://www.gstatic.com/firebasejs/8.10.0/firebase-firestore.js" crossorigin></script>';

        let JSflowroom =
          '<script src="../js/flowroom.js" crossorigin></script>';
        let freesound =
          '<script type="text/javascript" src="../js/freesound.js"></script>';
        let howlerjs = '<script src="../js/howler.min.js"></script>';
        let prefixfree =
          '<script src="https://cdnjs.cloudflare.com/ajax/libs/prefixfree/1.0.7/prefixfree.min.js"></script>';
        let normalize =
          '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css"></link>';
        let Quigga =
          '<script src="https://cdn.jsdelivr.net/npm/quagga/dist/quagga.min.js"></script>';

        let src = base_tpl.replace("</body>", html + "</body>");
        let srcCSS = "<style>" + css + "</style>";
        src = src.replace(
          "</head>",
          isNormalize
            ? normalize
            : '<link rel="stylesheet" href=""></link>' +
                srcCSS +
                howlerjs +
                freesound +
                Quigga +
                JSflowroom +
                prefixfree +
                fontawesome +
                "</head>"
        );
        let babel =
          '<script src="https://unpkg.com/babel-standalone@6.15.0/babel.min.js"></script>';

        let script = "";
        // if (
        //   libraries.length !== 0 &&
        //   libraries !== undefined &&
        //   libraries !== null
        // ) {
        //   for (let i = 0; i < libraries.length; i++) {
        //     script = script + `<script src="${libraries[i]}"></script>`;
        //   }
        // }
        // if (isBabel === true) {
        //   script =
        //     script +
        //     '<script src="https://unpkg.com/babel-standalone@6.15.0/babel.min.js"></script>';
        // }

        let scriptTag = '<script type="text/babel">';

        let srcJS = "<script>" + "try { " + js + " }catch(e){}" + "</script>";
        //scriptTag +js + "</script>";
        // : "<script>" + js + "</script>";

        src = src.replace(
          "</body>",
          script +
            srcJS +
            '<script src="../js/isLoaded.js">' +
            "</script>" +
            "</body>"
        );
        return src;
      } catch (e) {
        console.log("prepare", e);
      }
    };

    renderIframeHTML = () => {
      try {
        // let iframeCheck = document.getElementById("error_output_frame");
        let iframe = document.getElementById("undefined_output_frame");

        //if (iframe !== null && iframeCheck !== null) {
        let source = prepareSource(); // This remains unchanged
        
        // let iframeCheck = document.getElementById("error_output_frame");

        // if (iframe && iframeCheck) {
        // Create a Blob from the source
        const blob = new Blob([source], { type: "text/html" });
        const url = URL.createObjectURL(blob);

        // Set the Blob URL as the source of the iframe
        iframe.onload = () => {
          URL.revokeObjectURL(url);
        };
        if(source.trim() === "undefined" || source === undefined) {
          renderIframeHTML();
          return;
        } 
        iframe.srcdoc = source;

        // Error handling for the iframe
        // iframeCheck.contentWindow.onerror = function (
        //   msg,
        //   url,
        //   lineNo,
        //   columnNo,
        //   error
        // ) {
        //   // Error handling logic
        //   console.log("Error:", error);
        //   setErrorNumber(lineNo);
        //   setErrorMessage(msg);
        //   setErrorType(error);
        //   return true;
        // };

        // Clean up
        setErrorNumber(0);
        setErrorMessage("");
        setErrorType("");

        // Set up links to open in new tabs (optional)
        var iframeContent =
          iframe.contentDocument || iframe.contentWindow.document;
        var links = iframeContent.getElementsByTagName("a");
        for (var i = 0; i < links.length; i++) {
          links[i].setAttribute("target", "_blank");
        }
        // }
        // } catch (e) {
        //   console.error("Error rendering iframe content:", e);
        // }
      } catch (e) {
        console.log("prepare", e);
      }
    };

    window.reRender = function () {
      // renderIframeHTML();
    };

    window.updateJSCode = function (code) {
      // setJS(code);
    };

    window.updateHTMLCode = function (code) {
      // setHTML(code);
      // callNum++;
      // let html = htmlEditor && htmlEditor.current ? htmlEditor.current : "";
      // let css = cssEditor && cssEditor.current ? cssEditor.current : "";
      // let js = jsEditor && jsEditor.current ? jsEditor.current : "";
      //console.log("[Editor] window.updateHTMLCode html:", html, "css:", css, "js:", js);
      // DHTMLStore.setDHTML(html, css, js);
      // if (callNum + 1 === RemixableStore.result) {
      //   setTimeout(() => {
      //     window.reRender();
      //     callNum = 0;
      //   });
      // }
    };
  };

  useEffect(() => {
    // In the parent window
    let iframe = document.getElementById("undefined_outut_frame");
    if (iframe !== null) {
      iframe.contentWindow.changeText("new string");
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);


  // New useEffect to set isCollaborator
  useEffect(() => {
    if (!currentUser) {
      setIsCollaborator(false);
      return;
    }

    const db = firebase.firestore();
    const unsubscribe = db
      .collection("notifications")
      .where("receiver", "==", currentUser.displayName)
      .where("type", "==", "promoRequest")
      .onSnapshot(
        (snapshot) => {
          if (!snapshot.empty) {
            setIsCollaborator(true);
          } else {
            setIsCollaborator(false);
          }
        },
        (error) => {
          console.error("Error checking collaborator status:", error);
          setIsCollaborator(false);
        }
      );

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [currentUser]);

  function isValid(str) {
    return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
  }

  // const onResizeHtml = (width, height) => {
  //   let codeLabelHtml = document.getElementById("html-options-icons");
  //   //if (codeLabelHtml.clientWidth === width) {
  //   console.log("codeLabelW", width);
  //   // document.getElementById("html-handle-box").style.width = width + "px";

  //   if (width > 151) {
  //     // document.getElementById("html-icons-wrap").style.padding = "0px 50px";
  //   }
  //   if (width < 250) {
  //     document.getElementById("html-options-icons").style.visibility = "hidden";
  //     // document.getElementById("html-options-icons").style.display = "none";
  //     // document.getElementsByTagName('section')[0].style.paddingTop = '40px';
  //     // document.getElementsByTagName('section')[0].style.borderTop = '0px solid #3e3e3e';
  //     // document.getElementsByClassName('code-options')[0].style.display = 'block';
  //     // document.getElementsByClassName('code-options')[0].style.backgroundColor = 'rgb(28, 28, 28)';
  //     // document.getElementsByClassName('code-options')[0].style.height = '38px';
  //     // document.getElementsByClassName('code-options')[0].style.marginTop = '1px';

  //   }
  //   if (width > 250) {
  //     document.getElementById("html-options-icons").style.visibility =
  //       "visible";
  //       document.getElementById("html-options-icons").style.display = "block";
  //       // document.getElementsByTagName('section')[0].style.paddingTop = '0px';
  //       // document.getElementsByTagName('section')[0].style.borderTop = '1px solid #3e3e3e';
  //     //   document.getElementsByClassName('code-options')[0].style.display = 'none';
  //     //   document.getElementsByClassName('code-options')[0].style.height = '0px';
  //     // document.getElementsByClassName('code-options')[0].style.marginTop = '0px';


  //   }

  //   if (width < 230) {
  //     // document.getElementById("html-icons-wrap").style.padding = "0px 20px";
  //   }
  //   //}
  // };

  // const onResizeCss = (width, height) => {
  //   let codeLabelCss = document.getElementById("css-options-icons");
  //   // document.getElementById("css-handle-box").style.width = width + "px";

  //   if (width > 151) {
  //     // document.getElementById("css-icons-wrap").style.padding = "0px 50px";
  //   }
  //   if (width < 240) {
  //     // document.getElementById("css-options-icons").style.visibility = "hidden";
  //     // document.getElementById("css-options-icons").style.display = "none";
  //     // document.getElementsByTagName('section')[1].style.paddingTop = '40px';
  //     // document.getElementsByTagName('section')[1].style.borderTop = '0px solid #3e3e3e';
  //     // document.getElementsByClassName('code-options')[1].style.backgroundColor = 'rgb(28, 28, 28)';
  //     // document.getElementsByClassName('code-options')[1].style.display = 'block';
  //     // document.getElementsByClassName('code-options')[1].style.height = '38px';
  //     // document.getElementsByClassName('code-options')[1].style.marginTop = '1px';


  //   }
  //   if (width > 240) {
  //     // document.getElementById("css-options-icons").style.visibility = "visible";
  //     // document.getElementById("css-options-icons").style.display = "block";
  //     // document.getElementsByTagName('section')[1].style.paddingTop = '0px';
  //     // document.getElementsByTagName('section')[1].style.borderTop = '1px solid #3e3e3e';

  //     // document.getElementsByClassName('code-options')[1].style.display = 'none';
  //     // document.getElementsByClassName('code-options')[1].style.height = '0px';
  //     // document.getElementsByClassName('code-options')[1].style.marginTop = '0px';

  //   }

  //   if (width < 230) {
  //     // document.getElementById("css-icons-wrap").style.padding = "0px 20px";
  //   }
  // };

  // const onResizeJs = (width, height) => {
  //   // document.getElementById("js-handle-box").style.width = width + "px";

  //   if (width > 151) {
  //     // document.getElementById("js-icons-wrap").style.padding = "0px 50px";
  //   }
  //   if (width < 250) {
  //     // document.getElementById("js-options-icons").style.visibility = "hidden";
  //     // document.getElementById("js-options-icons").style.display = "none";
  //     // document.getElementsByTagName('section')[2].style.paddingTop = '40px';
  //     // document.getElementsByTagName('section')[2].style.borderTop = '0px solid #3e3e3e';
  //     // document.getElementsByClassName('code-options')[2].style.backgroundColor = 'rgb(28, 28, 28)';
  //     // document.getElementsByClassName('code-options')[2].style.display = 'block';
  //     // document.getElementsByClassName('code-options')[2].style.height = '38px';
  //     // document.getElementsByClassName('code-options')[2].style.marginTop = '1px';
  //   }
  //   if (width > 250) {
  //     // document.getElementById("js-options-icons").style.visibility = "visible";
  //     // document.getElementById("js-options-icons").style.display = "block";
  //     // document.getElementsByTagName('section')[2].style.paddingTop = '0px';
  //     // document.getElementsByTagName('section')[2].style.borderTop = '1px solid #3e3e3e';
  //     // document.getElementsByClassName('code-options')[2].style.display = 'none';
  //     // document.getElementsByClassName('code-options')[2].style.height = '0px';
  //     // document.getElementsByClassName('code-options')[2].style.marginTop = '0px';

  //   }

  //   if (width < 230) {
  //     // document.getElementById("js-icons-wrap").style.padding = "0px 20px";
  //   }
  // };

  const [imageSrc, setImageSrc] = useState('../images/remix-icon-white.svg');
  const [imageSaveSrc, setImageSaveSrc] = useState('../save_alt.svg');
  const [imageCreateSrc, setImageCreateSrc] = useState('../images/create-with-ai-icon.svg');
  const [imageEditSrc, setImageEditSrc] = useState('../images/create-with-ai-icon.svg');
  const [imageBookmarkSrc, setImageBookmarkSrc] = useState('../bookmark-unselected-white.svg');

  const handleRemixMouseOver = () => {
    setImageSrc('../images/remix-icon-dark.svg');
  };

  const handleRemixMouseOut = () => {
    setImageSrc('../images/remix-icon-white.svg');
  };

  const handleSaveMouseOver = () => {
    setImageSaveSrc('../save_alt_black.svg');
  };

  const handleSaveMouseOut = () => {
    setImageSaveSrc('../save_alt.svg');
  };

  const handleEditWithAIMouseOver = () => {
    setImageEditSrc('../images/create-with-ai-icon-dark.svg');
  };

  const handleEditWithAIMouseOut = () => {
    setImageEditSrc('../images/create-with-ai-icon.svg');
  };

  const handleCreateWithAIMouseOver = () => {
    setImageCreateSrc('../images/create-with-ai-icon-dark.svg');
  };

  const handleCreateWithAIMouseOut = () => {
    setImageCreateSrc('../images/create-with-ai-icon.svg');
  };

  const handleBookmarkMouseOver = () => {
    setImageBookmarkSrc('../bookmark-unselected-black.svg');
  };

  const handleBookmarkMouseOut = () => {
    setImageBookmarkSrc('../bookmark-unselected-white.svg');
  };



  return (
    <div id="full-page" className="full-page">
      {(() => {
        //console.log("[Editor] html", html);
        //console.log("[Editor] css", css);
        //console.log("[Editor] js", js);
      })()}
      <div className="editor-menu">
        
        <div
          className="top-box-wrap"
          style={{
            display: "none",
            flexDirection: "column",
            // paddingTop: 30,
            position:'relative',
            zIndex:1
          }}
        >
          <div
            className="top-boxes editor-parent"
            style={{
              padding: "0px 0px",
              width: "calc(100% - 43px)",
              background: "transparent",
              margin: "auto",
              left: 0,
              position: "relative",
            }}
            ref={editorParentRef}
          >
            <VelocityComponent
              id="velocity-box"
              animation={{
                height: EditorStore.showAnimation ? 300 : 0,
              }}
              duration={100}
              complete={()=>{}}
            >
              <ResizableBox
                id="resizable-box"
                width={width}
                // height={EditorStore.showAnimation ? 300 : 0}
                // onResize={(event, { size }) => {
                //   try {
                //   setBoxSize(size);
                //   } catch(error) {}
                // }}
                handle={<ResizeHandle />}
                // onResizeStart={editorDragStart}
                onResizeStop={editorDragStop}
                draggableOpts={{}}
                // minConstraints={[0, 0]}
                // resizeHandles={['se']} // Make sure to include 'se' to specify the handle is for the south-east corner
                // resizeHandles={["s"]}
                style={{
                  paddingBottom: 3,
                  marginTop: 0,
                  zIndex: 1000,
                  position:'relative'
                }}
              >
                <div className="editor-container">
                  <div className="block block-1">
                  
                          <ReactResizeDetector
                            handleWidth
                            handleHeight
                          />
                        
                    <div
                      onClick={() => {}}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          //if (nlpToCode === true) {
                          //}
                          // if (codeReview === true) {
                          // }
                        }
                        let iframe = document.getElementById(
                          "undefined_output_frame"
                        );
                        for (let i = 0; i < 100; i++) {
                          iframe.contentWindow.clearInterval(i);
                        }
                        clearTimeout(typingTimer);
                        if (htmlEditor) {
                          typingTimer = setTimeout(doneTyping, 1000);
                        }
                      }}
                      onKeyDown={(e) => {
                        // let parts = window.location.pathname.split("/");
                        // // let parts = window.location.pathname.split("/");
                        // let id = parts.pop() || parts.pop();
                        // let dontShow = JSON.parse(
                        //   localStorage.getItem("dontShowAgainEditor")
                        // );
                        // // if (
                        // //   dontShow == null ||
                        // //   dontShow == undefined ||
                        // //   dontShow.flowId !== id
                        // // ) {
                        // if (WidgetStore.widgets.length !== 0) {
                        //   WidgetStore.setShowWidgetMessage(true);
                        //   // WidgetStore.setWidgets([]);
                        //   return;
                        // }
                        // console.log(e.shiftKey);
                        // console.log(e.ctrlKey);
                        if (e.shiftKey && e.ctrlKey) {
                          // getHTMLfromText();
                        }
                        //}
                      }}
                      style={{
                        paddingTop: 20,
                        // background: "#222222",
                        height: "100%",
                        // resize: "horizontal",
                        // overflow: "auto",
                        // width: "100%",
                      }}
                    >
                      <div
                        className="code-box-label"
                        style={{
                          alignItems: "center",
                          display: "flex",
                          width: "100%",

                          backgroundColor: "#1C1C1C",
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15,
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <div
                          className="code-label-wrapper"
                          style={{
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100%",
                            }}
                            onClick={() => {}}
                          >
                            <img
                              src="/html-icon.svg"
                              style={{ marginLeft: 18, marginRight: 7 }}
                            />
                            <p
                              className="code-label-html"
                              style={{ fontFamily: "quicksand" }}
                              onClick={() => {
                                // document.getElementsByClassName(
                                //   "block-1"
                                // )[0].style.display = "block";
                                // document.getElementsByClassName(
                                //   "block-2"
                                // )[0].style.display = "none";
                                // document.getElementsByClassName(
                                //   "block-3"
                                // )[0].style.display = "none";
                                // EditorStore.setRemixWizard(true);
                              }}
                            >
                              HTML
                            </p>
                            <div
                              id="html-options-icons"
                              style={{
                                height: "40px",
                                width: "100%",
                                backgroundColor: "rgb(34, 34, 34)",
                                top: "4px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                id="html-icons-wrap"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: 55,
                                  backgroundColor: "transparent",
                                  top: 65,
                                  alignItems: 'center',
                                  visibility: 'visible',
                                  position: 'absolute',
                                  right: 0,
                                  /* height: 116px; */
                                  flexDirection: 'column',
                                  zIndex: 1,
                                  /* opacity: 0.2; */
                                  /* padding: 7px; */
                                  height: 137
                                }}
                              >
                                <div
                                  onClick={() => {
                                    let format = htmlEditor.current
                                      .getAction("editor.action.formatDocument")
                                      .run();
                                    htmlEditor.current.setValue(format);
                                  }}
                                  style={{ zIndex: 1000, cursor: "pointer", marginTop:10 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faHandSparkles}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    EditorStore.setCodeHTMLWizard(true);
                                    // setAnswers([]);
                                    // setAnswerSelected(false);
                                  }}
                                  style={{ zIndex: 1000, cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faHatWizard}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div
                                  onClick={() => {}}
                                  style={{ zIndex: 1000, cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faEar}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div
                                  onClick={() => EditorStore.setHTMLGear(true)}
                                  style={{ zIndex: 1000, cursor: "pointer", marginBottom:10 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCog}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div style={{height:'100%', width:'30px', backgroundColor:'#FFF', position:'absolute', opacity:0.1, borderRadius:20}}></div>
                              </div>
                            </div>
                            <div className="chev-down">
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                color="#fff"
                                size={"2x"}
                                style={{
                                  display: "block",
                                  right: "10px",
                                  top: "10px",
                                }}
                                onClick={() => {}}
                              />
                            </div>
                            <div
                              style={{ position: "absolute", right: 20 }}
                              onClick={() => {
                                EditorStore.setRemixWizard(true);
                                WidgetStore.setGetSelectedRemix(true);
                                WidgetStore.setEditorSelected("html");
                              }}
                            ></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {/* <div
                          className="code-options"
                          style={{
                            position: "absolute",
                            background: "#3F3F3E",
                            height: 38,
                            width: "100%",
                            zIndex: 1000,
                          }}
                        ></div> */}

                     
                        <Editor
                          value={html}
                          onMount={handleHTMLEditorDidMount}
                          theme={"vs-dark"}
                          language="html"
                          height={"100%"}
                          width={"100%"}
                          onChange={() => {
                            PublishMenuStore.setShowShare(false);
                          }}
                          id="html-editor"
                          options={{
                            minimap: { enabled: false },
                            wordWrap: "on",
                            padding: 0,
                            acceptSuggestionOnCommitCharacter: true,
                            acceptSuggestionOnEnter: "off",
                            accessibilitySupport: "auto",
                            autoIndent: true,
                            automaticLayout: true,
                            codeLens: false,
                            colorDecorators: true,
                            contextmenu: false,
                            cursorBlinking: "blink",
                            cursorSmoothCaretAnimation: false,
                            cursorStyle: "line",
                            disableLayerHinting: true,
                            disableMonospaceOptimizations: false,
                            dragAndDrop: false,
                            fixedOverflowWidgets: false,
                            folding: true,
                            foldingStrategy: "auto",
                            fontLigatures: false,
                            formatOnPaste: true,
                            formatOnType: true,
                            hideCursorInOverviewRuler: false,
                            highlightActiveIndentGuide: false,
                            links: false,
                            mouseWheelZoom: false,
                            multiCursorMergeOverlapping: true,
                            multiCursorModifier: "alt",
                            overviewRulerBorder: true,
                            overviewRulerLanes: 2,
                            quickSuggestions: false,
                            quickSuggestionsDelay: 100,
                            readOnly: false,
                            renderControlCharacters: false,
                            renderFinalNewline: true,
                            renderIndentGuides: true,
                            renderLineHighlight: "all",
                            renderWhitespace: "none",
                            revealHorizontalRightPadding: 30,
                            roundedSelection: false,
                            resize: "none",
                            rulers: [],
                            scrollBeyondLastColumn: 5,
                            suggest: false, // Disable suggestions

                            selectOnLineNumbers: true,
                            selectionClipboard: true,
                            selectionHighlight: false,
                            showFoldingControls: "mouseover",
                            smoothScrolling: true,
                            suggestOnTriggerCharacters: false,
                            wordBasedSuggestions: false,
                            wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
                            wordWrapBreakAfterCharacters: "\t})]?|&,;",
                            wordWrapBreakBeforeCharacters: "{([+",
                            wordWrapBreakObtrusiveCharacters: ".",
                            wordWrapColumn: 80,
                            wordWrapMinified: true,
                            wrappingIndent: "none",
                            scrollBeyondLastLine: false,
                            fontFamily: "quicksand",
                            fontSize: "14px",
                            fontWeight: "bold",
                            // lineNumbers: "off",
                            fontFamily: "quicksand",
                            fontSize: "15.5px",
                            fontWeight: "bold",
                            suggestOn: "never",
                            quickSuggestions: false,
                            parameterHints: false,
                            autoClosingBrackets: false,
                            autoClosingQuotes: false,
                            autoSurround: "never",
                            wordBasedSuggestions: false,
                            hover: { enabled: false },
                            zIndex:100
                          }}
                          containerClassName="editor-container"
                          editorClassName="editor-content"
                        />
                        {/* <div
                          style={{
                            backgroundColor: "#2D2A2B",
                            height: "40px",
                            width: "100%",
                            position: "absolute",
                            bottom: "0px",
                            zIndex: -5,
                            borderLeft: "5.4px solid #000",
                            left: 1,
                          }}
                        ></div> */}
                        {/* <span className="react-resizable-handle react-resizable-handle-se"></span> */}
                      </div>
                      <div className="resize-handle"></div>

                    </div>
                  </div>

                  <div className="slider-handle"></div>
                 

                  <div className="block block-2">
                          <ReactResizeDetector
                            handleWidth
                            handleHeight
                          />
                    <div
                      onKeyUp={(e) => {
                        // if (e.key === "Enter") {
                        //   getCSSfromText();
                        // }
                        let iframe = document.getElementById(
                          "undefined_output_frame"
                        );
                        for (let i = 0; i < 100; i++) {
                          iframe.contentWindow.clearInterval(i);
                        }
                        clearTimeout(typingTimer);
                        if (cssEditor?.current) {
                          typingTimer = setTimeout(doneTyping, 1000);
                        }
                      }}
                      onKeyDown={(e) => {
                        let parts = window.location.pathname.split("/");
                        // let parts = window.location.pathname.split("/");
                        let id = parts.pop() || parts.pop();
                        // let dontShow = JSON.parse(
                        //   localStorage.getItem("dontShowAgainEditor")
                        // );

                        // if (
                        //   dontShow == null ||
                        //   dontShow == undefined ||
                        //   dontShow.flowId !== id
                        // ) {
                        // if (WidgetStore.widgets.length !== 0) {
                        //   for (let i = 0; i < WidgetStore.widgets.length; i++) {
                        //     if (WidgetStore.widgets[i].widget !== "AIWidget") {
                        //       WidgetStore.setShowWidgetMessage(true);

                        //       return;
                        //     }
                        //   }

                        // WidgetStore.setWidgets([]);

                        //}
                        //}
                        console.log(e.shiftKey);
                        console.log(e.ctrlKey);
                        if (e.shiftKey && e.ctrlKey) {
                          // getCSSfromText();
                        }
                      }}
                      style={{
                        paddingTop: 20,
                        height: "100%",
                        // resize: "horizontal",
                        // overflow: "auto",
                        // width: "100%",
                      }}
                    >
                      <div
                        className="code-box-label"
                        style={{
                          alignItems: "center",
                          display: "flex",
                          width: "100%",
                          backgroundColor: "#1C1C1C",
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15,
                          position: "relative",
                        }}
                      >
                        <div
                          className="code-label-wrapper"
                          style={{
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/css-icon.svg"
                            style={{ marginLeft: 18, marginRight: 7 }}
                          />
                          <p
                            className="code-label-css"
                            style={{ fontFamily: "quicksand" }}
                            onClick={() => {
                              // document.getElementsByClassName(
                              //   "block-1"
                              // )[0].style.display = "none";
                              // document.getElementsByClassName(
                              //   "block-2"
                              // )[0].style.display = "block";
                              // document.getElementsByClassName(
                              //   "block-3"
                              // )[0].style.display = "none";
                            }}
                          >
                            CSS
                          </p>

                          <div
                              id="css-options-icons"
                              style={{
                                height: "40px",
                                width: "100%",
                                backgroundColor: "transparent",
                                top: "4px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                id="css-icons-wrap"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: 55,
                                  backgroundColor: "transparent",
                                  top: 65,
                                  alignItems: 'center',
                                  visibility: 'visible',
                                  position: 'absolute',
                                  right: 0,
                                  /* height: 116px; */
                                  flexDirection: 'column',
                                  zIndex: 1,
                                  /* opacity: 0.2; */
                                  /* padding: 7px; */
                                  height: 137
                                }}
                              >
                                <div
                                  onClick={() => {
                                    let format = cssEditor.current.getValue();

                                  format = format.replace(
                                    /\s*([\{\}\:\;\,])\s*/g,
                                    "$1"
                                  );
                                  format = format.replace(/;\s*;/g, ";");
                                  format = format.replace(
                                    /\,[\s\.\#\d]*{/g,
                                    "{"
                                  );
                                  format = format.replace(
                                    /([^\s])\{([^\s])/g,
                                    "$1 {\n\t$2"
                                  );
                                  format = format.replace(
                                    /([^\s])\}([^\n]*)/g,
                                    "$1\n}\n$2"
                                  );
                                  format = format.replace(
                                    /([^\s]);([^\s\}])/g,
                                    "$1;\n\t$2"
                                  );

                                  cssEditor.current.setValue(format);
                                  }}
                                  style={{ zIndex: 1000, cursor: "pointer", marginTop:10 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faHandSparkles}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    EditorStore.setCodeHTMLWizard(true);
                                    // setAnswers([]);
                                    // setAnswerSelected(false);
                                  }}
                                  style={{ zIndex: 1000, cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faHatWizard}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div
                                  onClick={() => {}}
                                  style={{ zIndex: 1000, cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faEar}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div
                                  onClick={() => EditorStore.setHTMLGear(true)}
                                  style={{ zIndex: 1000, cursor: "pointer", marginBottom:10 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCog}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div style={{height:'100%', width:'30px', backgroundColor:'#FFF', position:'absolute', borderRadius:20, opacity:0.1}}></div>
                              </div>
                            </div>








                          <div className="chev-down">
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              color="#fff"
                              size={"2x"}
                              style={{
                                display: "block",
                                right: "10px",
                                top: "10px",
                              }}
                              onClick={() => {}}
                            />
                          </div>
                          <div
                            onClick={() => {
                              EditorStore.setRemixWizard(true);
                              WidgetStore.setGetSelectedRemix(true);
                              WidgetStore.setEditorSelected("css");
                            }}
                            style={{ position: "absolute", right: 20 }}
                          ></div>
                        </div>
                      </div>

                      <div
                        style={{
                          position: "relative",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {/* <div
                          className="code-options"
                          style={{
                            position: "absolute",
                            background: "transparent",
                            height: 38,
                            width: "100%",
                            zIndex: 10000,
                          }}
                        ></div> */}
                        <Editor
                          value={css}
                          ref={cssEditor}
                          theme="vs-dark"
                          language="css"
                          height={"100%"}
                          onMount={handleCSSEditorDidMount}
                          onChange={() => {
                            PublishMenuStore.setShowShare(false);
                          }}
                          options={{
                            minimap: { enabled: false },
                            wordWrap: "on",
                            padding: 0,
                            acceptSuggestionOnCommitCharacter: true,
                            acceptSuggestionOnEnter: "on",
                            accessibilitySupport: "auto",
                            autoIndent: true,
                            automaticLayout: true,
                            codeLens: true,
                            colorDecorators: true,
                            contextmenu: true,
                            cursorBlinking: "blink",
                            cursorSmoothCaretAnimation: false,
                            cursorStyle: "line",
                            disableLayerHinting: false,
                            disableMonospaceOptimizations: false,
                            dragAndDrop: false,
                            fixedOverflowWidgets: false,
                            folding: true,
                            foldingStrategy: "auto",
                            fontLigatures: false,
                            formatOnPaste: true,
                            formatOnType: true,
                            hideCursorInOverviewRuler: false,
                            highlightActiveIndentGuide: false,
                            links: true,
                            mouseWheelZoom: false,
                            multiCursorMergeOverlapping: true,
                            multiCursorModifier: "alt",
                            overviewRulerBorder: true,
                            overviewRulerLanes: 2,
                            quickSuggestions: false,
                            quickSuggestionsDelay: 100,
                            readOnly: false,
                            renderControlCharacters: false,
                            renderFinalNewline: true,
                            renderIndentGuides: true,
                            renderLineHighlight: "all",
                            renderWhitespace: "none",
                            revealHorizontalRightPadding: 30,
                            roundedSelection: false,
                            resize: "none",
                            rulers: [],
                            scrollBeyondLastColumn: 5,

                            selectOnLineNumbers: true,
                            selectionClipboard: true,
                            selectionHighlight: false,
                            showFoldingControls: "mouseover",
                            smoothScrolling: true,
                            suggestOnTriggerCharacters: false,
                            wordBasedSuggestions: false,
                            wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
                            wordWrapBreakAfterCharacters: "\t})]?|&,;",
                            wordWrapBreakBeforeCharacters: "{([+",
                            wordWrapBreakObtrusiveCharacters: ".",
                            wordWrapColumn: 80,
                            wordWrapMinified: true,
                            wrappingIndent: "none",
                            scrollBeyondLastLine: false,
                            // lineNumbers: "off",
                            fontFamily: "quicksand",
                            fontSize: "15.5px",
                            fontWeight: "bold",
                            quickSuggestions: false,
                            parameterHints: false,
                            autoClosingBrackets: false,
                            autoClosingQuotes: false,
                            autoSurround: "never",
                            wordBasedSuggestions: false,
                            hover: { enabled: false },
                            suggest: false, // Disable suggestions
                          }}
                        />
                        {/* <div
                          style={{
                            backgroundColor: "#2D2A2B",
                            height: "40px",
                            width: "100%",
                            position: "absolute",
                            bottom: "0px",
                            zIndex: -5,
                            borderLeft: "5.4px solid #000",
                            left: 1,
                          }}
                        ></div> */}
                      </div>
                    </div>
                    <div className="resize-handle2"></div>

                  </div>
                  <div className="slider-handle2"></div>
                  <div className="block block-3">
                    <div
                      onKeyUp={() => {
                        let iframe = document.getElementById(
                          "undefined_output_frame"
                        );
                        for (let i = 0; i < 100; i++) {
                          iframe.contentWindow.clearInterval(i);
                        }
                        clearTimeout(typingTimer);
                        if (jsEditor?.current) {
                          typingTimer = setTimeout(doneTyping, 1000);
                        }
                      }}
                      onKeyDown={(e) => {
                        // let parts = window.location.pathname.split("/");
                        // // let parts = window.location.pathname.split("/");
                        // let id = parts.pop() || parts.pop();
                        // let dontShow = JSON.parse(
                        //   localStorage.getItem("dontShowAgainEditor")
                        // );

                        // if (
                        //   dontShow == null ||
                        //   dontShow == undefined ||
                        //   dontShow.flowId !== id
                        // ) {
                        //   if (WidgetStore.widgets.length !== 0) {
                        //     WidgetStore.setShowWidgetMessage(true);
                        //     // WidgetStore.setWidgets([]);

                        //     return;
                        //   }
                        // }
                        console.log(e.shiftKey);
                        console.log(e.ctrlKey);
                        if (e.shiftKey && e.ctrlKey) {
                          // getJSfromText();
                        }
                      }}
                      style={{
                        paddingTop: 20,
                        background: "transparent",
                        height: "100%",
                      }}
                    >
                      <div
                        className="code-label-wrapper"
                        style={{
                          backgroundColor: "#1C1C1C",
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15,
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          src="/js-icon.svg"
                          style={{ marginLeft: 18, marginRight: 7 }}
                        />
                        <p
                          className="code-label-js"
                          style={{
                            height: 33,
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          JS
                        </p>




                        <div
                              id="js-options-icons"
                              style={{
                                height: "40px",
                                width: "100%",
                                backgroundColor: "transparent",
                                top: "4px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                id="js-icons-wrap"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: 55,
                                  backgroundColor: "transparent",
                                  top: 65,
                                  alignItems: 'center',
                                  visibility: 'visible',
                                  position: 'absolute',
                                  right: 0,
                                  /* height: 116px; */
                                  flexDirection: 'column',
                                  zIndex: 1,
                                  /* opacity: 0.2; */
                                  /* padding: 7px; */
                                  height: 137
                                }}
                              >
                                <div
                                  onClick={() => {
                                    let format = jsEditor.current
                                    .getAction("editor.action.formatDocument")
                                    .run();
                                  jsEditor.current.setValue(format);
                                  }}
                                  style={{ zIndex: 1000, cursor: "pointer", marginTop:10 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faHandSparkles}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    EditorStore.setCodeHTMLWizard(true);
                                    // setAnswers([]);
                                    // setAnswerSelected(false);
                                  }}
                                  style={{ zIndex: 1000, cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faHatWizard}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div
                                  onClick={() => {}}
                                  style={{ zIndex: 1000, cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faEar}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div
                                  onClick={() => EditorStore.setHTMLGear(true)}
                                  style={{ zIndex: 1000, cursor: "pointer", marginBottom:10 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCog}
                                    color="white"
                                    style={{ display: "flex", fontSize: 14 }}
                                  />
                                </div>
                                <div style={{height:'100%', width:'30px', backgroundColor:'#FFF', position:'absolute', borderRadius:20, opacity:0.1}}></div>
                              </div>
                            </div>

                        






                        <div
                          onClick={() => {
                            EditorStore.setRemixWizard(true);
                            WidgetStore.setGetSelectedRemix(true);
                            WidgetStore.setEditorSelected("js");
                          }}
                          style={{ position: "absolute", right: 20 }}
                        ></div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {/* <div
                          style={{
                            position: "absolute",
                            background: "#3F3F3E",
                            height: 38,
                            width: "100%",
                            zIndex: 10000,
                          }}
                          className="code-options"
                        >
                          {" "}
                        </div> */}

                        <div>
                          <ReactResizeDetector
                            handleWidth
                            handleHeight
                          />
                        </div>
                        <Editor
                          value={js}
                          height={"100%"}
                          width={"100%"}
                          theme="vs-dark"
                          language="javascript"
                          onMount={handleJSEditorDidMount}
                          onChange={() => {
                            PublishMenuStore.setShowShare(false);
                          }}
                          options={{
                            minimap: { enabled: false },
                            wordWrap: "on",
                            padding: 0,
                            acceptSuggestionOnCommitCharacter: true,
                            acceptSuggestionOnEnter: "on",
                            accessibilitySupport: "auto",
                            autoIndent: true,
                            automaticLayout: true,
                            codeLens: true,
                            colorDecorators: true,
                            contextmenu: true,
                            cursorBlinking: "blink",
                            cursorSmoothCaretAnimation: false,
                            cursorStyle: "line",
                            disableLayerHinting: false,
                            disableMonospaceOptimizations: false,
                            dragAndDrop: false,
                            fixedOverflowWidgets: false,
                            folding: true,
                            foldingStrategy: "auto",
                            fontLigatures: false,
                            formatOnPaste: true,
                            formatOnType: true,
                            hideCursorInOverviewRuler: false,
                            highlightActiveIndentGuide: false,
                            links: true,
                            mouseWheelZoom: false,
                            multiCursorMergeOverlapping: true,
                            multiCursorModifier: "alt",
                            overviewRulerBorder: true,
                            overviewRulerLanes: 2,
                            quickSuggestions: false,
                            quickSuggestionsDelay: 100,
                            readOnly: false,
                            renderControlCharacters: false,
                            renderFinalNewline: true,
                            renderIndentGuides: true,
                            renderLineHighlight: "all",
                            renderWhitespace: "none",
                            revealHorizontalRightPadding: 30,
                            roundedSelection: false,
                            resize: "none",
                            rulers: [],
                            scrollBeyondLastColumn: 5,

                            selectOnLineNumbers: true,
                            selectionClipboard: true,
                            selectionHighlight: false,
                            showFoldingControls: "mouseover",
                            smoothScrolling: true,
                            suggestOnTriggerCharacters: false,
                            wordBasedSuggestions: false,
                            wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
                            wordWrapBreakAfterCharacters: "\t})]?|&,;",
                            wordWrapBreakBeforeCharacters: "{([+",
                            wordWrapBreakObtrusiveCharacters: ".",
                            wordWrapColumn: 80,
                            wordWrapMinified: true,
                            wrappingIndent: "none",
                            scrollBeyondLastLine: false,
                            fontFamily: "quicksand",
                            fontSize: "14=5.5px",
                            fontWeight: "bold",
                            // lineNumbers: "off",
                            hover: { enabled: false },
                            suggest: false, // Disable suggestion
                          }}
                        />
                        <div
                          style={{
                            width: "calc(100% - 12px)",
                            background: "#F06263",
                            wordBreak: "break-all",
                            top: 45,
                            position: "absolute",
                            fontFamily: "quicksand",
                            color: "white",
                            padding: 20,
                            left: 7,
                            display: errorNumber !== 0 ? "flex" : "none",
                            margin: "auto",
                            fontSize: 15,
                            display: "none",
                          }}
                        >
                          <p
                            onClick={() => {
                              setErrorNumber(0);
                              setErrorMessage("");
                            }}
                            style={{
                              color: "white",
                              fontFamily: "quicksand",
                              position: "absolute",
                              right: "8px",
                              top: "0px",
                              cursor: "pointer",
                            }}
                          >
                            x
                          </p>
                          {errorMessage} Line: {errorNumber}
                        </div>
                        <div
                          onClick={() => {
                            jsEditor.current.revealLineInCenter(errorNumber);
                          }}
                          style={{
                            height: "20px",
                            width: "20px",
                            background: "#F06263",
                            position: "absolute",
                            bottom: "21px",
                            right: "25px",
                            display: errorNumber !== 0 ? "flex" : "none",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 16,
                            fontFamily: "Quicksand",
                            color: "white",
                            borderRadius: "50%",
                            fontWeight: "bolder",
                            zIndex: 10000,
                            cursor: "pointer",
                          }}
                        >
                          <p
                            style={{ color: "white", fontFamily: "quicksand" }}
                          >
                            !
                          </p>
                        </div>

                      </div>
                    </div>
                    <div className="resize-handle3"></div>
                  </div>
                  {(() => {
                    setTimeout(() => {
                      // let block = document.querySelector(".block-1");
                      // let block3 = document.querySelector(".block-3");
                      // let slider = document.querySelector(".slider-handle");
                      // let slider2 = document.querySelector(".slider-handle2");
                      // if (slider !== null) {
                      //   slider.onmousedown = function dragMouseDown(e) {
                      //     let dragX = e.clientX;
                      //     document.onmousemove = function onMouseMove(e) {
                      //       block.style.width =
                      //         block.offsetWidth + e.clientX - dragX + "px";
                      //       dragX = e.clientX;
                      //       // setHtmlWidth(
                      //       //   block.offsetWidth + e.clientX - dragX + "px"
                      //       // );
                      //     };
                      //     // remove mouse-move listener on mouse-up
                      //     document.onmouseup = () =>
                      //       (document.onmousemove = document.onmouseup = null);
                      //   };

                      //   slider2.onmousedown = function dragMouseDown(e) {
                      //     let dragX = e.clientX;
                      //     document.onmousemove = function onMouseMove(e) {
                      //       block3.style.width =
                      //         block3.offsetWidth - e.clientX + dragX + "px";
                      //       dragX = e.clientX;
                      //     };
                      //     // remove mouse-move listener on mouse-up
                      //     document.onmouseup = () =>
                      //       (document.onmousemove = document.onmouseup = null);
                      //   };
                      // }

let block = document.querySelector(".block-1");
  let block3 = document.querySelector(".block-3");
  let slider = document.querySelector(".slider-handle");
  let slider2 = document.querySelector(".slider-handle2");
  let resizer = document.querySelector('.resize-handle');
  let resizer2 = document.querySelector('.resize-handle2');
  let resizer3 = document.querySelector('.resize-handle3');

  let allBlocks = document.querySelectorAll('.block');

  // Function to resize width
  function resizeWidth(slider, block, increase = true) {
    slider.onmousedown = function(e) {
      let dragX = e.clientX;
      document.onmousemove = function(e) {
        let change = increase ? e.clientX - dragX : dragX - e.clientX;
        block.style.width = `${block.offsetWidth + change}px`;
        dragX = e.clientX;
      };
      document.onmouseup = function() {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  }

  if (slider !== null) {
    resizeWidth(slider, block, true); // Increase width
    resizeWidth(slider2, block3, false); // Decrease width
  }

  // Resize all blocks' height
  if (resizer) {
    resizer.onmousedown = function(e) {
      let startY = e.clientY;
      let originalHeights = Array.from(allBlocks).map(block => block.offsetHeight);
      document.getElementById('resizable-box').style.height = '100%';
      document.onmousemove = function(e) {
        let deltaY = e.clientY - startY;
        allBlocks.forEach((block, index) => {
          block.style.height = `${originalHeights[index] + deltaY}px`;
        });
      };

      document.onmouseup = function() {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  }

  if (resizer2) {
    resizer2.onmousedown = function(e) {
      let startY = e.clientY;
      let originalHeights = Array.from(allBlocks).map(block => block.offsetHeight);
      document.getElementById('resizable-box').style.height = '100%';

      document.onmousemove = function(e) {
        let deltaY = e.clientY - startY;
        allBlocks.forEach((block, index) => {
          block.style.height = `${originalHeights[index] + deltaY}px`;
        });
      };

      document.onmouseup = function() {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  }

  if (resizer3) {
    resizer3.onmousedown = function(e) {
      let startY = e.clientY;
      let originalHeights = Array.from(allBlocks).map(block => block.offsetHeight);
      document.getElementById('resizable-box').style.height = '100%';

      document.onmousemove = function(e) {
        let deltaY = e.clientY - startY;
        allBlocks.forEach((block, index) => {
          block.style.height = `${originalHeights[index] + deltaY}px`;
        });
      };

      document.onmouseup = function() {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  }

                      // document.addEventListener('DOMContentLoaded', () => {
                      //   // Handles height resizing for all blocks
                      //   const resizeHandle = document.querySelector('.resize-handle');
                      //   resizeHandle.addEventListener('mousedown', function(event) {
                      //     const startY = event.clientY;
                      //     const originalHeights = Array.from(document.querySelectorAll('.block')).map(block => block.offsetHeight);
                      
                      //     function onMouseMove(event) {
                      //       const deltaY = event.clientY - startY;
                      //       document.querySelectorAll('.block').forEach((block, index) => {
                      //         block.style.height = `${originalHeights[index] + deltaY}px`;
                      //       });
                      //     }
                      
                      //     function onMouseUp() {
                      //       document.removeEventListener('mousemove', onMouseMove);
                      //       document.removeEventListener('mouseup', onMouseUp);
                      //     }
                      
                      //     document.addEventListener('mousemove', onMouseMove);
                      //     document.addEventListener('mouseup', onMouseUp);
                      //   });
                      
                      //   // Handles width resizing for individual blocks
                      //   document.querySelectorAll('[class^="slider-handle"]').forEach((handle, index) => {
                      //     handle.addEventListener('mousedown', function(event) {
                      //       const startX = event.clientX;
                      //       const block = document.querySelector(`.block-${index + 1}`); // Assumes block-1, block-2, block-3, etc.
                      //       const originalWidth = block.offsetWidth;
                      
                      //       function onMouseMove(event) {
                      //         const deltaX = event.clientX - startX;
                      //         block.style.width = `${originalWidth + deltaX}px`;
                      //       }
                      
                      //       function onMouseUp() {
                      //         document.removeEventListener('mousemove', onMouseMove);
                      //         document.removeEventListener('mouseup', onMouseUp);
                      //       }
                      
                      //       document.addEventListener('mousemove', onMouseMove);
                      //       document.addEventListener('mouseup', onMouseUp);
                      //     });
                      //   });
                      // });
                      

                    }, 10000);
                  })()}
              
                </div>
              </ResizableBox>
            </VelocityComponent>
          </div>
        </div>
      </div>


      
      <div
        style={{
          position: "absolute",
          backgroundColor: "#323232",
          height: "100%",
          width: "100%",
        }}
      ></div>
         <div id="preview-bar" style={{backgroundColor: '#1C1C1C',
    height: 58,
    width: 'calc(100% - 48px)',
    zIndex: 1,
    top: 24,
    position: 'relative',
    margin: 'auto', borderTopLeftRadius:20,borderTopRightRadius:20 }}>
      <img src="/images/preview.svg" style={{height:20, width:20, 
    width: 90,
    position: 'relative',
    top: 20,
    left: 10}}/>
    </div>
    {FlowStore.isFlowOwner === true ? (<div className="top-tabs-mobile" style={{display:id === "new" ? "none":"block"}}>
    <TopTabsMobile id={id}/>
    </div>):""}
      {loading == false ? (
        <div className="iframe-container">
          <iframe
            id="undefined_output_frame"
            // key={location.pathname}
            className="output_frame"
            loading="lazy"
            sandbox="
            allow-scripts
            allow-same-origin
            allow-forms
            allow-downloads
            allow-popups
            allow-popups-to-escape-sandbox
          "
          allow="microphone; camera; autoplay; encrypted-media; fullscreen"
            style={{
              display: "block",
              top: 0,
              left: 0,
              zIndex: 1,
              marginTop: 0,
              width: "100%",
              height: "100%",
              borderRadius: 0,
            }}
          ></iframe>

          {EditorStore.visibleIcons == true ? (<div
            className="overlay-icons"
          >
            <div
              style={{
                width: "100%"
              }}
            >
              {FlowStore.isSaving == false ? (
                <div
                  onClick={() => {
                    if (currentUser == null) {
                      AuthenticationStore.setShowModal(true);
                      return;
                    }
                    if (
                      currentUser.displayName != null &&
                      currentUser.displayName != ""
                    ) {
                      EditorStore.setShowPostModal(true);

                    } else {
                      AuthenticationStore.setShowModal(true);
                    }
                  }}
                  onMouseOver={handleSaveMouseOver}
                  onMouseOut={handleSaveMouseOut}
                  className="save-btn-create-or-remix save-btn-create-or-remix-"
                >
                  <img
                    src={imageSaveSrc}
                    style={{
                      height: 20,
                      width: 20,
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                  ></img>
                  <p
                    style={{
                      fontFamily: "quicksand",
                      fontSize: 12,
                      marginBottom: 0,
                      height: 17,
                      marginLeft: 4,
                      cursor: "pointer",
                    }}
                  >
                    Save
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                width: "100%",
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              {/* {FlowStore.isSaving == false &&
          EditorStore.showRemixWithWidgetButton == true &&
          FlowStore.currentRoute !== "new" ? ( */}
              {/* {EditorStore.showRemixWithWidgetButton == true &&
          FlowStore.currentRoute !== "new" ? (<div
                onClick={() => {
                  // EditorStore.setSa
                  let iframe = document.getElementById("undefined_output_frame");
                  //iframe.onload = function() {
                    var iframeWindow = iframe.contentWindow;

                   if(isCurrentlyRemixing == false) {
                    if (WidgetStore.startRemixing) {
                        iframeWindow[WidgetStore.startRemixing]();
                       setIsCurrentlyRemixing(true);

                    } else {
                        console.error("options object or startRemixing property not found in iframe");
                    }
                  } else {
                    if (WidgetStore.startRemixing) {
                      iframeWindow[WidgetStore.endRemixing]();
                     setIsCurrentlyRemixing(false);

                  } else {
                      console.error("options object or startRemixing property not found in iframe");
                  }
                  }
                //};

                }}
                onMouseOver={handleRemixMouseOver}
                onMouseOut={handleRemixMouseOut}
                className="save-btn-create-or-remix remix-with-widgets"
              >
                <img

                  src={imageSrc}
                  style={{
                    height: 15,
                    width: 15,
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
               
                ></img>
                <p
                  style={{
                    fontFamily: "quicksand",
                    fontSize: 12,
                    marginBottom: 0,
                    height: 17,
                    marginLeft: 7,
                    cursor: "pointer",
                  }}
                >
                  {isCurrentlyRemixing ? 'Stop Remixing' : 'Remix'} 
                </p>
              </div>):""} */}
              {/* ) : (
            ""
          )} */}
            </div>
            <div style={{ width: "100%", marginBottom:20 }}
                 >


  
        
            </div>



          </div>):""}

<div style={{display:'flex',  position: "absolute",
                  marginLeft: 0, zIndex:100, bottom:0, flexWrap:'wrap', display:'flex'}}>


</div>




        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100%",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        ></div>
      )}

      <div id="more-mobile" style={{    height: "100vh",
    width: "100%",
    backgroundColor: "rgb(28, 28, 28)",
    zIndex: 1000,
    position: "absolute",
    bottom: "77px",
    maxHeight: 237,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    overflow: "hidden",
    left: 0,
    borderBottom: "1px solid rgb(57, 57, 57)",
    }}>

      </div>
      {id !== "new" ? <MobileTabPanel /> :""} 
      {/* <DHTML_Output flowId={props.flowId} /> */}
      {/* code wizard html begin */}
      <CodeWizard />
      {/* code wizard html end */}
      {/*code password required*/}
      {/*end of code password required*/}
      {/*no widgets available*/}
      {/* no widgets available */}
      {/* edit ideas */}
      {/* error message */}
      {/*add collaborator*/}
      <PaymentModal />
      <ImproveFeedbackModal />
      <AIRequestModal />
      <CreateGuide />
      {EditorStore.showPostModal ? (<Post flowId={FlowStore.flowId} />):""}
      <AICreateModal roomId={EditorStore.flowId}/>
      <MoreModal/>
      <CommentsModal        open={CommentsStore.isShowModal}
            close={() => {
              CommentsStore.setIsShowModal(false);
            }}/>
              <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
            zIndex: 1,
            display: showWidget == true ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: "none",
          }}
        >
          <div
            id="remix-widget-modal"
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              top: 0,
              right: 0,
              height: "100%",
              width: "100%",
              background: "black",
              opacity: 0.25,
              borderRadius: 0,
              zIndex:1000
            }}
          ></div>
          {widgets ? (<RemixWidgetModal
            key={Math.random()}
            // widgets={props.widgets}
            widget={widgets.widget}
            startFunction={widgets.startFunction}
            stopFunction={widgets.stopFunction}
            functionName={widgets.functionName}
            widgetLabel={widgets.widgetLabel}
            items={widgets.items}
            imageId={widgets.imageId}
            imgSrc={widgets.imgSrc}
            showRemixWidgetModal={showWidget}
            closeRemixWidgetModal={() => {
              setShowWidget(false);
            }}
            flowId={props.flowId}
            // deductCreditFrom={props.deductCreditFrom}
      
            // username={props.username}
            uid={props.uid}
            trainMode={widgets.trainMode}
          />):""}
        </div>
        {showUseLater ? <UseLaterModal openModal={showUseLater} closeModal={()=>{
          setShowUseLater(false);
        }}/> : ""}

      {/* add collaborator */}
      <style>
        {`
     .full-page {
    display: flex;
    flex-direction: column;
    position:relative;
}


.iframe-container {
    flex-grow: 1; /* Take remaining space */
    position: relative; /* Needed for absolute positioning of iframe */
}

#mobile-details {
  display:none;
}



        `}
      </style>
      <ReactModal
        ariaHideApp={false}
        closeTimeoutMS={500}
        isOpen={showAuthModal}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#3F3F3E",
            overflow: "hidden",
            borderRadius: "15px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% / 1.1)",
            maxWidth: 570,

                  // maxHeight: WidgetStore.currentModalHeight,
          },
        }}         className="auth-modal">
          <div
            onClick={() => {
              setShowAuthModal(false);
            }}
            style={{
              position: "absolute",
              right: 0,
              margin: 10,
              cursor: "pointer",
              zIndex: 1,
            }}>
            <img src="/images/close-circle.svg"   style={{
                display: "block",
                position: "absolute",
                right: 10,
                top: 10,
              }}/>
          </div>

        <LoginM /> 
        </ReactModal>
    </div>
  );
});

export default React.memo(RoomEditor);
